import * as dtmApi from "../../datalayer-api";
import cartProductObject from "../../objects/CartProductObject";
import { addEventHandler, EVENTS } from "./customEventTrigger";
import { getCurrencyCode } from "../../../helpers/currencyCodeHelper";

const eventAdd = "EECaddToCart";
const eventRemove = "EECremoveFromCart";
addEventHandler(EVENTS.CART_ADD, () => {
  return performCartTracking(eventAdd);
});
addEventHandler(EVENTS.CART_REMOVE, () => {
  return performCartTracking(eventRemove);
});

async function performCartTracking(eventName) {
  const product = await cartProductObject.getData();
  if (Object.keys(product).length === 0) {
    return;
  }
  try {
    const trackingData = { event: eventName };
    trackingData.ecommerce =
      eventName === eventAdd
        ? {
            currencyCode: getCurrencyCode(window.location),
            add: { product }
          }
        : {
            remove: { product }
          };
    dtmApi.sendData(trackingData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`cart-tracking error while ${eventName}: ${err}`);
  }
}
