export { DataLayerPromotion };

/**
 * Klasse zum Erstellen eines GA4 Promotion Objekts.
 * Gibt einzelne Informationen oder ein vollständiges Promotion-Objekt zurück.
 *
 * @param {Node} element - DOM Node, der z.B. beim Klick-Event übergeben wird.
 *
 */
class DataLayerPromotion{
	constructor(element) {
		this.element = element;
	}

	get promotionParameters() {
		return this.build();
	}

	get component() {
		if (this.element.hasAttribute('component')) {
			return this.element;
		}
		return this.element.closest('[component]') || null;
	}

	get promotionElement() {
		if (this.element.hasAttribute('promotion-name')) {
			return this.element;
		}
		return this.element.closest('[promotion-name]') || null;
	}

	get creativeElement() {
		if (this.element.hasAttribute('promotion-creative')) {
			return this.element;
		}
		return this.element.closest('[promotion-creative]') || null;
	}

	get componentName() {
		return this.component?.getAttribute('component') || "";
	}

	get promotionName() {
		return this.promotionElement.getAttribute('promotion-name');
	}

	get promotionCreative() {
		return this.creativeElement.getAttribute('promotion-creative');
	}

	get position() {
		const creativeElements = this.promotionElement.querySelectorAll('[promotion-creative]');
		const promotionCreative = this.promotionCreative;

		const index = Object.values(creativeElements).findIndex(element => {
			return element.getAttribute('promotion-creative') === promotionCreative;
		});

		return index < 0 ? 0 : (index + 1);
	}

	build() {
		const promotionObj = {};
		promotionObj['component'] = this.componentName;
		promotionObj['promotion_name'] = this.promotionName;
		promotionObj['promotion_creative'] = this.promotionCreative;
		promotionObj['position'] = this.position;
		return promotionObj;
	}
}
