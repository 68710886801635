export { initContentsquare, setDynamicVariables, setCustomVariables, csTrackPageView, csTrackAddToCart, csTrackPurchase, csTrackDialog, csTrackDialogClose };

function initContentsquare(dps) {
	const { scriptUrl } = dps.serviceSettings;

	initContentsquareObject();

	window._uxa.push(['setPath', window.location.pathname + window.location.hash.replace('#', '?__')]);

	if (typeof CS_CONF === 'undefined') {
		loadScript(scriptUrl);
	}
}

function initContentsquareObject() {
	window._uxa = window._uxa || [];
}

function setDynamicVariables(datalayerModel) {
	initContentsquareObject();
	const dynamicVariables = [
		{
			key: "ab-test",
			value: datalayerModel['ab-test'] || datalayerModel['ab_test_search'] || undefined
		}
	]
	dynamicVariables.forEach((item) => {
		if(typeof(item.value) !== 'undefined') {
			window._uxa.push(['trackDynamicVariable', {key: item.key, value: item.value}]);
		}
	});
}

function setCustomVariables(datalayerModel) {
	initContentsquareObject();
	// Die bestehenden Variablen dürfen nicht geändert werden. Relevant in Contentsquare ist die ID, nicht der Name!
	const customVariables = [
		{
			id: 1,
			key: "shopid",
			value: datalayerModel?.shopid || datalayerModel?.attributes?.shopid,
			scope: "page"
		},
		{
			id: 2,
			key: "seitentyp",
			value: datalayerModel?.seitentyp || datalayerModel?.attributes?.seitentyp,
			scope: "page"
		},
		{
			id: 3,
			key: "usertyp",
			value: getUserLoginStatus(datalayerModel),
			scope: "page"
		},
		{
			id: 4,
			key: "hersteller",
			value: datalayerModel?.hersteller || datalayerModel?.attributes?.hersteller,
			scope: "page"
		},
		{
			id: 5,
			key: "artikelID",
			value: datalayerModel?.id || datalayerModel?.attributes?.id,
			scope: "page"
		},
		{
			id: 6,
			key: "cat1",
			value: getCategory(datalayerModel, 0),
			scope: "page"
		},
		{
			id: 7,
			key: "cat2",
			value: getCategory(datalayerModel, 1),
			scope: "page"
		},
		{
			id: 8,
			key: "cat3",
			value: getCategory(datalayerModel, 2),
			scope: "page"
		},
		{
			id: 9,
			key: "cat4",
			value: getCategory(datalayerModel, 3),
			scope: "page"
		},
		{
			id: 10,
			key: "cat5",
			value: getCategory(datalayerModel, 4),
			scope: "page"
		},
	];
	customVariables.forEach((item) => {
		if(typeof(item.value) !== 'undefined') {
			window._uxa.push(['setCustomVariable', item.id, item.key, item.value, item.scope]);
		}
	});
}

function getCategory(datalayerModel, index) {
	const kategoriepfad = datalayerModel?.kategoriepfadname || datalayerModel?.attributes?.kategoriepfadname;
	if(kategoriepfad === undefined) {
		return undefined;
	}
	if(datalayerModel?.seitentyp !== "kategorieseite-redaktionell" && datalayerModel?.seitentyp !== "bereich") {
		return undefined;
	}

	const kategorien = kategoriepfad.split("|");
	if(kategorien.length === 0 || kategorien.length < index) {
		return undefined;
	}

	return kategorien[index];
}

function getUserLoginStatus(datalayerModel) {
	if(typeof(datalayerModel["user-id"]) === "undefined") {
		return "anonym";
	}
	return "eingeloggt";
}

function loadScript(src) {
	const script = document.createElement("script");
	script.src = src;
	script.type = "text/javascript";
	script.async = 1;
	document.getElementsByTagName("head")[0].appendChild(script);
}

function csTrackPageView(path = "", datalayerModel = false) {
	initContentsquareObject();
	if(!!datalayerModel) {
		setCustomVariables(datalayerModel);
		setDynamicVariables(datalayerModel);
	}
	let trackedPath = path;
	if( path === "") {
		trackedPath = window.location.pathname + window.location.hash.replace('#', '?__');
	}
	window._uxa.push(['trackPageview', trackedPath]);
}

function csTrackAddToCart(message) {
	initContentsquareObject();
	window._uxa.push(['trackPageEvent', 'addToCart']);
	window._uxa.push(['ec:cart:add', {
		merchant: "Thalia",
		sku: message.products[0].id
	}]);
}

function csTrackPurchase(message) {
	initContentsquareObject();
    window._uxa.push(['ec:transaction:create', {
		'id': message.attributes.id,
        'revenue': message.attributes.revenue,
        'currency': 'EUR'
    }]);
    window._uxa.push(['ec:transaction:send']);
}

function csTrackDialog(message) {
	initContentsquareObject();
	const { dialog_name } = message.attributes;

	if(dialog_name) {
		csTrackPageView(`/dialog/${dialog_name}`);
	}
}

// beim schließen von Dialogen wieder ein artificial pageview auf die ursprüngliche Seite getracked
function csTrackDialogClose(message) {
	initContentsquareObject();
	const { dialog_name } = message.attributes;
	if (dialog_name) {
		csTrackPageView();
	}
}
