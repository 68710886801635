export default {
	"name": "ThaliaChat",
	"stage": "produktion",
	"mandanten": ["2"],
	"pagetypes": "all",
	"seitenPattern": /(\/shop\/mayersche\/show\/|\/shop\/home\/login\/|\/shop\/home\/kunde\/|\/shop\/home\/kunde\/rechnungen\/|\/shop\/home\/auftraege\/letzteAuftraege\/|\/shop\/home\/kontakt\/|\/hilfe\/navigation|\/hilfe\/kaufprozess\/|\/hilfe\/rechtliches\/|\/hilfe\/anleitungen\/|\/hilfe\/sicherheit-umwelt\/|\/hilfe\/vorteilsprogramme\/)/g,
	"serviceSettings": {
		"jspUrl": "https://chat.thalia.de/chatRest/",
		"frontendId": "frontend-thalia",
		"scriptSrc": "https://ecdn.novomind.com/rules/la/nm.rules.js"
	}
}
