import { register } from "./error-logging";

const shell = {
	name: "Shell",
	release: "1.0.0",
	dsn: "https://22cba5852c04d9893399ec81f33e5172@sentry.thalia.de/3",
	filenameRegex: [
		/shell.*\.js$/,
		/shell-consent-uc.*\.js$/,
  ],
};
register(shell);

