/* eslint-disable compat/compat */
import clickObject from "../objects/ClickObject";

clickObject.register(target => {
  return new Promise((resolve, reject) => {
    const component = target.closest("[component]");
    if (!component) {
      reject(
        new Error(
          `Cannot get tracking data from ${target.outerHTML} \nThe element should be inside of an element with an [component] attribute.`
        )
      );
    }

    const category = component.getAttribute("component");
    const action = getAction(target);
    const label = getLabel(target);
    const nonInteractionHit = getInteractionHit(target);

    const trackingAttributes = Array.from(target.attributes)
      .filter(attr => attr.name.startsWith("tracking-"))
      .reduce((accu, attr) => ({ ...accu, [attr.name.substring(9)]: attr.value }), {})

    const data = {
      ...trackingAttributes,
      eventCategory: category,
      eventAction: action || category,
      eventLabel: label,
      nonInteractionHit: nonInteractionHit
    };
    resolve(data);
  });
});

function getLabel(target) {
  return target.getAttribute("caption") || target.innerText.trim();
}

function getAction(target) {
  return isSummary(target) ? getActionFromDetails(target) : getActionFromInteraction(target);
}

function getInteractionHit(target) {
  if(target.closest("[non-interaction")) {
    return "1";
  }
  return "0";
}

function getActionFromInteraction(target) {
  return target.closest("[interaction]")
    ? target.closest("[interaction]").getAttribute("interaction")
    : undefined;
}

function getActionFromDetails(target) {
  const detailsOpen = target.closest("details").open
  return detailsOpen ? "details-summary-close" : "details-summary-open";
}

function isSummary(target) {
  return target.tagName === "SUMMARY";
}

