/* eslint-disable compat/compat */
import isEqual from "deep-equal";
import * as dtmApi from "../../../datalayer-api";
import { getCurrencyCode } from "../../../../helpers/currencyCodeHelper";
import productObject from "../../../objects/ProductObject";

const queue = [];
const queueSize = 50;
const intersectionThreshold = 0.8;
let useBeforeUnloadEvents = true;

if (isLegacyBrowser()) {
  useBeforeUnloadEvents = false;
}

function isLegacyBrowser() {
  if (Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject) {
      return true;
  }
  return false;
}

const intObserver = new IntersectionObserver(
  entries =>
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        performProductTracking(entry.target);
      }
    }),
  { threshold: intersectionThreshold }
);

const config = { childList: true, subtree: true };
const mutObserver = new MutationObserver(mutations =>
  mutations.forEach(mutation => {
    if (mutation.type === "childList") {
      mutation.addedNodes.forEach(node => handleObservation(node));
      mutation.removedNodes.forEach(node => handleObservation(node, false));
    }
  })
);

document.addEventListener("DOMContentLoaded", () => {
  mutObserver.observe(document.body, config);
  handleObservation(document);
});

if (useBeforeUnloadEvents === true) {
  window.addEventListener("beforeunload", () => {
    if (!queueSizeReached()) {
      dtmApi.sendData(createEventFromQueue(queue.length % queueSize));
      return;
    }
  });
}

function handleObservation(element, observe = true) {
  // mutObserver finds some nodes, that do not have querySelectorAll
  if (element.querySelectorAll) {
    element.querySelectorAll("[impression] [product-id],[impression][product-id]").forEach(product => {
      if (observe) {
        intObserver.observe(product);
      } else {
        intObserver.unobserve(product);
      }
    });
  }
}

async function performProductTracking(target) {
  try {
    const trackingData = await productObject.getData(target);
    if (Object.keys(trackingData).length === 0) {
      return;
    }
    if (isNotInQueue(trackingData)) {
      queue.push(trackingData);
      if (queueSizeReached()) {
        dtmApi.sendData(createEventFromQueue(trackingData));
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`product-tracking error: ${err}`);
  }
}

function createEventFromQueue(impressionsCount = queueSize) {
  const impressions = queue.slice(queue.length - impressionsCount);
  const customProperties = impressions
    .map(data => data.actionList.customProperties)
    .reduce(mergeObjects, {});
  return {
    event: "EECproductImpression",
    nonInteraction: true,
    ecommerce: {
      currencycode: getCurrencyCode(window.location),
      impressions,
      ...customProperties
    }
  }
}

function mergeObjects(prevObj, currentObj) {
  return { ...prevObj, ...currentObj };
}

function queueSizeReached() {
  return queue.length % queueSize === 0;
}

function isNotInQueue(trackingData) {
  return !queue.some(data => isEqual(trackingData, data));
}
