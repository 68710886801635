/* eslint-disable compat/compat */
import productObject from "../objects/ProductObject";
import { getProductDataFromElement } from "../helper/extract-product-data";

export {getProductDataFromElement};

const compatabilityModeActive = true;

productObject.register(
  productElement =>
    new Promise((resolve, reject) => {
      const componentElement = productElement.closest("[component]");
      if (!componentElement) {
        reject(
          new Error(
            `Cannot get tracking data from ${productElement.outerHTML} \nThe element should be inside of an element with an [component] attribute.`
          )
        );
      }
      const data = getProductDataFromElement(productElement, compatabilityModeActive);
      resolve(data);
    })
);


