/* eslint-disable compat/compat */
import checkoutObject from "../objects/CheckoutObject";
import checkoutProductsObject from "../objects/CheckoutProductsObject";
import { getAllProductData, getCheckoutData } from "../helper/extract-product-data";


class DataLayerCheckout extends HTMLElement {

	connectedCallback() {

		checkoutObject.register(() => new Promise((resolve, reject) => {
			try {
				resolve(getCheckoutData(this));
			} catch (error) {
				reject(error);
			}
		}));
		checkoutProductsObject.register(() => new Promise((resolve, reject) => {
			try {
				const data = getAllProductData(this);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		}));
	}
}

if (!customElements.get('dl-checkout')) {
	customElements.define('dl-checkout', DataLayerCheckout);
}
