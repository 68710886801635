import { DataLayerPromotion } from "../../DataLayerPromotion";

export default buildSelectPromotionData;

function buildSelectPromotionData(promotionCreative) {
	const eventData = {
		event: "select_promotion"
	}
	const { promotionParameters } = new DataLayerPromotion(promotionCreative);
	eventData['attributes'] = promotionParameters;
	return eventData;
}
