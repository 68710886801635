import { getProductAttributionData } from "../product-attribution/productAttribution";
import { DataLayerCustomProperties } from "./DataLayerCustomProperties";

export { DataLayerItem };

/**
 * Klasse zum Erstellen eines GA4 Item Objekts.
 * Gibt einzelne Informationen, ein reduziertes und/oder
 * ein vollständiges Item-Objekt zurück.
 *
 * @param {Node} element - DOM Node, der z.B. beim Klick-Event übergeben wird
 * @param {Node} component - DOM Node der umschließenden Komponente
 * @param {Boolean} attributionRequired - Gibt an, ob zusätzliche Daten erforderlich sind
 * @param {Boolean} componentAttributesRequired - Gibt an, ob die Custom Properties der Komponente ergänzt werden sollen
 *
 */
class DataLayerItem {
	constructor(element, component = null, attributionRequired = false, componentAttributesRequired = false) {
		this.element = element;
		this.component = component;
		this.attributionRequired = attributionRequired;
		this.componentAttributesRequired = componentAttributesRequired;
	}

	get productId() {
		return this.element?.getAttribute("product-id");
	}

	get componentName() {
		return this.component?.getAttribute('component') || "";
	}

	get productlist() {
		return this.component?.getAttribute('product-list') || "";
	}

	get position() {
		// Wenn kein [component] Attribut vorhanden ist,
		// wird die Position nicht ermittelt.
		if (!this.component) {
			return null;
		}
		// Wenn eine explizite Auszeichnung "product-position" existiert,
		// wird die Position über "productAttributes" bestimmt.
		// Sonst versuchen wir die Position automatisch zu bestimmen.
		if (this.element.hasAttribute("product-position") || this.element.hasAttribute("position")) {
			return undefined;
		}
		let position;
		let productElements;
		const dlEventParentNode = this.element.closest("dl-event");
		if (dlEventParentNode) {
			productElements = dlEventParentNode?.querySelectorAll("[product-id]") || [];
		} else {
			productElements = this.component?.querySelectorAll("[product-id]") || [];
		}
		productElements.forEach((element, index, list) => {
			if (element === this.element) {
				position = index + 1;
				return;
			}
		})
		if (position) {
			return position.toString();
		}
		return null;
	}

	get productAttributes() {
		const elementAttributes = Array.from(this.element.attributes);
		return new DataLayerCustomProperties(elementAttributes, true).build();
	}

	get componentAttributes() {
		const componentAttributes = Array.from(this.component?.attributes);
		return new DataLayerCustomProperties(componentAttributes).build();
	}

	build() {
		const productObj = {};
		for (const [key, value] of Object.entries(this.productAttributes)) {
			// Ignore [dl-event] Attribute
			if (key !== "dl-event") {
				Object.assign(productObj, {
					[removeProductPrefix(key)]: value
				})
			}
		}
		if (this.position) {
			productObj["position"] = this.position;
		}
		if (this.component) {
			productObj['component'] = this.componentName;
		}
		if (this.productlist) {
			productObj['list'] = this.productlist;
		}
		if (this.attributionRequired) {
			Object.assign(productObj, getProductAttributionData(this.productId));
		}
		// Stand jetzt nur notwendig für view_item_list Event
		// TODO: klären, ob weiterhin notwendig. Ggf. für alle Events interessant?
		if (this.componentAttributesRequired) {
			for (const [key, value] of Object.entries(this.componentAttributes)) {
				Object.assign(productObj, {
					[key]: value
				})
			}
		}
		return productObj;
	}
}

function removeProductPrefix(string) {
	if (string.indexOf("product") === -1) return string;
	return string.replace("product-", "");
}
