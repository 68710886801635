import { DataLayerEntry } from "../../DataLayerEntry";

export default buildSelectItemData;

function buildSelectItemData(dataElement) {
	try {
		return new DataLayerEntry("select_item", dataElement).build();
	} catch (error) {
		console.error(error);
	}
}
