export function getStage(url) {
	if (url.indexOf('integration') !== -1) {
		return 'integration';
	}
	if (url.indexOf('ereader-integ') !== -1) {
		return 'integration';
	}
	if (url.indexOf('dev') !== -1) {
		return 'dev';
	}
	if (url.indexOf('www') !== -1) {
		return 'produktion';
	}
	if (url.indexOf('ereader') !== -1) {
		return 'produktion';
	}
	return 'local';
}

