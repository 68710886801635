import pageObject from "../objects/PageObject";
import { sendData } from "../datalayer-api";

export {
	transformDatatypes
}

class DataLayerPageView extends HTMLElement {
	connectedCallback() {
		const data = this.getData();
		sendData(data);

		pageObject.register(() => new Promise((resolve, reject) => {
			try {
				const data = this.getData();
				resolve(data);
			} catch (error) {
				reject(error);
			}
		}));

	}

	getData() {
		const rawData = this.getAttributes();
		const transformedData = transformDatatypes(rawData);
		return transformedData;
	}

	getAttributes() {
		const element = this;
		const attributeList = element.getAttributeNames().reduce((accu, name) => ({ ...accu, [name]: element.getAttribute(name) }), {});
		return attributeList;
	}
}

if (!customElements.get('dl-pageview')) {
	customElements.define('dl-pageview', DataLayerPageView);
}

function transformDatatypes(data) {
	for (const [key, value] of Object.entries(data)) {
		if (isString(value)) {
			transformationHandler(key, value, data);
		}
	}
	return data;
}

function transformationHandler(key, value, data) {
	if (isTrueOrFalse(value)) {
		data[key] = value === "true";
	}
	if (isNumber(value)) {
		data[key] = Number(value);
	}
}

function isString(value) {
	return typeof value === "string";
}

function isTrueOrFalse(value) {
	return value === "true" || value === "false";
}

function isNumber(value) {
	if(value.indexOf("0") === 0) {
		return false;
	}
	return !!Number(value);
}
