export default {
	"name": "Braze",
	"stage": "integration",
	"mandanten": ["2", "4", "5", "37", "51"],
	"pagetypes": "all",
	"serviceSettings": {
		scriptUrl: '//js.appboycdn.com/web-sdk/5.6/braze.min.js',
		"2": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "410ab6df-664c-4d2b-8b73-9318ca1ef909",
			"showInAppMessages": false
		},
		"4": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "b9b4c172-7cfb-46e7-afb5-e908dabe73f1",
			"showInAppMessages": false
		},
		"5": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "1bc335d4-e857-411c-9d91-bec3650352ca",
			"showInAppMessages": true
		},
		"37": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "005495f1-3495-4f5c-b64a-b526a7cc8333",
			"showInAppMessages": false
		},
		"51": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "774727b1-491b-4e85-8c79-afe9bd2fb469",
			"showInAppMessages": false
		}
	}
}
