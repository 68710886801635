import { sendData } from "../datalayer-api";
import { DataLayerEntry } from "../DataLayerEntry";
import { isValidComponent } from "./click/default";

export function dlEventHandler(event, baseParams = {}) {
	const { type, target } = event;
	if (!isValidComponent(target)) return;
	const validTypes = ["copy"]; /* alle Typen außer "click" */
	if (validTypes.includes(type) || isValidClickType(event, validTypes)) {
		const dlData = getDatalayerData(target, baseParams);
		const dlDataEmpty = isEmptyObject(dlData);
		if (!dlData || dlDataEmpty) return;
		return sendData(dlData);
	}
}

function isValidClickType(event, validTypes) {
	const { type, target } = event;
	const dataElement = getDataElement(target);
	const dlEventType = dataElement.getAttribute("dl-event");
	return type === "click" && !validTypes.includes(dlEventType);
}

export function getDatalayerData(target, baseParams) {
	const dataElement = getDataElement(target);
	const eventName = dataElement.getAttribute("dl-event");
	if (!dataElement) return null;
	const data = new DataLayerEntry(eventName, dataElement, baseParams).build();
	return data;
}

export function getDataElement(target) {
	try {
		if (target.hasAttribute("dl-event")) {
			return target;
		}
		const parentWithDlEventAttr = target.closest("[dl-event]");
		if (parentWithDlEventAttr) {
			return parentWithDlEventAttr;
		}
		return null;
	} catch (error) {
		console.error(error);
	}
}

export function isEmptyObject(obj) {
	if (obj) {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	}
	return false;
}

export function dlEventAttributeIsPresent(target) {
	if (target.hasAttribute("dl-event")) {
		return true;
	}
	const dlEventParent = target.closest("[dl-event]");
	if (dlEventParent) {
		return true;
	}
	return false;
}
