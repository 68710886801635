export default getDeviceSize;

function getDeviceSize() {
	const mobile = mediaQuery("max-width", 480);
	if(mobile === true) {
		return "mobile";
	}
	return "desktop";
  }


function mediaQuery(property, value) {
	return window.matchMedia(`screen and (${property}:${value}px)`).matches;
}
