import * as dtmApi from "../../../datalayer-api";
import promotionObject from "../../../objects/PromotionObject";
import { registerClickTracker } from "./click-tracking";

registerClickTracker(
  element => element.closest("[impression='promotion']"),
  performPromotionClickTracking
);

async function performPromotionClickTracking(element) {
  const trackingData = await promotionObject.getData(element);
  if (Object.keys(trackingData).length === 0) {
    return;
  }
  try {
    dtmApi.sendData(createPromotionClickEvent(trackingData));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`click-tracking error: ${err}`);
  }
}

function createPromotionClickEvent(data) {
  return {
    event: "EECpromotionClick",
    ecommerce: {
      promoClick: {
        promotions: [data]
      },
      ...data.customProperties
    },

  };
}
