// Nach Absprache mit unseren Webanalysten (Patrick Horstmann, Fiete Mensing) greifen wir
// auf das benutzerdefinierte "click" Event als Default Klick Event zurück.
// Das von Google empfohlene "select_content" Event verwenden wir nicht.
export function buildClickData(target) {
	return {
		event: "click",
		component: target.closest("[component]").getAttribute("component"),
		interaction: getInteraction(target),
		label: getLabel(target)
	}
}

export function getInteraction(target) {
	const summaryElement = isSummary(target);
	if (summaryElement) {
		return getInteractionForDetailsElement(target);
	}
	return getDefaultInteraction(target);
}

function getDefaultInteraction(target) {
	if (target.hasAttribute("interaction")) {
		return target.getAttribute("interaction");
	}
	const parentWithInteraction = target.closest("[interaction]");
	if (parentWithInteraction) {
		return parentWithInteraction.getAttribute("interaction");
	}
	
	return target.textContent.trim();
}

function getInteractionForDetailsElement(target) {
	const detailsOpen = target.closest("details").open
	return detailsOpen ? "details-summary-close" : "details-summary-open";
}

function isSummary(target) {
	return target.tagName === "SUMMARY";
}

function getLabel(target) {
	const caption = target.getAttribute("caption");
	if (caption) {
		return caption;
	}
	return target.textContent.trim();
}
