import * as dtmApi from "../../datalayer-api";
import optionObject from "../../objects/OptionObject";
import { isValidForFormTracking } from "../form";

document.addEventListener("change", ({ target }) => {
  if (isValidForFormTracking(target)) {
    performOptionTracking(target);
  }
});


async function performOptionTracking(element) {
  const trackingData = await optionObject.getData(element);
  
  if (Object.keys(trackingData).length === 0) {
    return;
  }
  try {
    dtmApi.sendData(createOptionEvent(trackingData));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`option-tracking error: ${err}`);
  }
}

function createOptionEvent(data) {
  return {
    event: "gaEvent",
    ...data
  };
}
