/* eslint-disable compat/compat */
/* eslint-disable no-undef */
import "data-layer-helper/dist/data-layer-helper";

export {
	getCallbackString,
	getProtocolForMandantId,
	isAppMandant,
	getMandantId,
	extractDataFromModel,
	isCallbackFunctionPresent,
	isAppWebview,
	isExternalLink,
	isInternalLink,
	callbackForExternalLink,
	initClickHandling
};

window.callbackHelper = new DataLayerHelper(getAppEventDatalayer(), listenerFunction, true);
initClickHandling();

function initClickHandling() {
	document.addEventListener("click", clickHandler);
}

function listenerFunction(message, model) {
	const {functionName, paramObject} = extractDataFromModel(model);

	if (tryToCallWebkitFunction(functionName, paramObject)) {
		return;
	}
	if (tryToCallAndroidFunction(functionName, paramObject)) {
		return;
	}
}

function clickHandler(event) {
	const mandantId = getMandantId();
	let absoluteUrl = false;
	if (!isAppMandant(mandantId)) {
		return true;
	}
	const link = event.target.closest("a[target=_blank], [callback], a[href]");
	if (!link || isInternalLink(link)) {
		return true;
	}

	let typeOfCallback = "openInInternalBrowser";
	if (isExternalLink(link)) {
		typeOfCallback = callbackForExternalLink(link);
		if (!typeOfCallback) {
			return true;
		}
	} else {
		typeOfCallback = link.getAttribute("callback") || "openInInternalBrowser";
	}

	try {
		let url = link.getAttribute('callback-url');
		if (!url) {
			absoluteUrl = true;
			url = link.getAttribute('href');
		}
		const callbackString = getCallbackString(url, mandantId, typeOfCallback, absoluteUrl);
		const datalayer = getAppEventDatalayer();
		datalayer.push({url: callbackString});
		event.preventDefault();
	} catch (e) {
		console.log(e);
		return true;
	}
}

function callbackForExternalLink(link) {
	if (link.hasAttribute("callback")) {
		const callback = link.getAttribute("callback");
		if (callback === "pdf") {
			return "showPdf";
		}
		if (!isCallbackFunctionPresent(callback)) {
			return false;
		}
		return callback;
	}
	return "openInExternalBrowser";
}

function extractCallbackDataFromUrl(modelUrl) {
	let url;
	try {
		// eslint-disable-next-line compat/compat
		url = new URL(modelUrl);
	} catch (e) {
		return e;
	}

	if (isProtocolForbidden(url.protocol)) {
		return '2';
	}

	let functionName = "";
	try {
		functionName = url.pathname.replace("//", "");
		if (functionName === "") {
			functionName = url.hostname.replace("//", "");
		}
	} catch (e) {
		return;
	}

	const paramObject = getParamsObject(url.search);

	return {
		functionName,
		paramObject
	}
}

function getParamsObject(searchParams) {
	const searchParamsObject = new URLSearchParams(searchParams);
	let paramObject = {};
	searchParamsObject.forEach((value, key) => {
		paramObject[key] = value;
	});
	return paramObject;
}

function extractCallbackDataFromCallback(callback, params = "") {
	const functionName = callback;
	const paramObject = getParamsObject(params);
	return {
		functionName,
		paramObject
	}
}

function extractDataFromModel(model) {
	let functionName = "";
	let paramObject = {};

	if (model.url) {
		({ functionName, paramObject } = extractCallbackDataFromUrl(model.url));
	} else if (model.callback) {
		({ functionName, paramObject } = extractCallbackDataFromCallback(model.callback, model.params));
	}
	return { functionName, paramObject };
}

function isProtocolForbidden(protocol) {
	const allowedProtocols = ['thaliaxcapp:', 'thaliaoftpp:', 'epublishing:'];
	if (allowedProtocols.indexOf(protocol) === -1) {
		return true;
	}
	return false;
}

export function getAppEventDatalayer() {
	window.appCallback = window.appCallback || [];
	return window.appCallback;
}

function isCallbackFunctionPresent(callbackName, checkFor) {
	if(checkFor === "webkit") {
		return checkWebkitCallback(callbackName);
	} else if(checkFor === "android") {
		return checkAndroidCallback(callbackName);
	} else {
		return checkWebkitCallback(callbackName) || checkAndroidCallback(callbackName);
	}
}

function checkWebkitCallback(callbackName) {
	if (typeof(window.webkit) !== "undefined" && typeof (window.webkit.messageHandlers[callbackName]) === "object" && typeof (window.webkit.messageHandlers[callbackName].postMessage) === "function") {
		return true;
	}
	return false;
}

function checkAndroidCallback(callbackName) {
	if(typeof(window.javascriptInterface) !== "undefined" && typeof (window.javascriptInterface[callbackName]) === "function") {
		return true;
	}
	return false;
}

function tryToCallWebkitFunction(callbackName, parameters) {
	try {
		if (checkWebkitCallback(callbackName)) {
			window.webkit.messageHandlers[callbackName].postMessage(parameters)
			return true;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
}

function tryToCallAndroidFunction(callbackName, parameters) {
	try {
		if (checkAndroidCallback(callbackName)) {
			window.javascriptInterface[callbackName](JSON.stringify(parameters))
			return true;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
}

/**
 * Ein Link ist dann extern, wenn er ein `href` Attribute hat, dieses nicht leer ist und der `hostname` sich vom
 * `hostname` des `window.location` unterscheidet.
 *
 * @param {Element} element
 */
function isExternalLink(element) {
	if (!element.hasAttribute('href') || element.getAttribute('href').trim().length === 0) {
		return false;
	}
	try {
		const href = element.getAttribute('href');
		const linkUrl = new URL(href, window.location.origin);
		if (linkUrl.protocol !== "https:" && linkUrl.protocol !== "http:") {
			return false;
		}
		const linkHostname = linkUrl.hostname;
		const thisHostname = window.location.hostname;
		return linkHostname !== thisHostname;
	} catch (e) {
		return false;
	}
}

/**
 * @param {Element} link
 * @returns {boolean}
 */
function isInternalLink(link) {
	return !isExternalLink(link) && '_blank' !== link.getAttribute('target') && !link.hasAttribute('callback');
}

function isAppWebview() {
	const mandantId = getMandantId();
	return isAppMandant(mandantId);
}

function getMandantId() {
	let mandantId = "";
	try {
		mandantId = document.querySelector("[name='mandant']").content;
	} catch (e) {
		mandantId = document.body.getAttribute('data-mandant');
	}
	return mandantId;
}

function isAppMandant(mandantId) {
	switch (mandantId) {
		case "2003":
		case "2005":
		case "4002":
		case "4003":
		case "37002":
		case "37004":
		case "51003":
			return true;
	}
	return false;
}

/**
 * Gibt für die übergebene Mandanten-ID das Callback-Protokoll zurück.
 * @param {String} mandantId
 * @returns {String} Protokoll für den Callbacl
 */
function getProtocolForMandantId(mandantId) {
	switch (mandantId) {
		case "2003":
		case "4002":
		case "51003":
			return "thaliaxcapp";
		case "37002":
			return "thaliaoftpp";
		case "2005":
		case "4003":
		case "37004":
			return "epublishing";
	}
	return "";
}

function getCallbackString(href, mandantId, typeOfCallback, absoluteUrl = true) {
	if(typeOfCallback === "pdf") {
		typeOfCallback = "showPdf";
	}
	let callbackMethod = typeOfCallback ?? 'openInInternalBrowser';
	const protocol = getProtocolForMandantId(mandantId);
	let callbackString = href;

	if (href.indexOf(protocol) === -1) {
		let callbackUrl = href;

		if (typeOfCallback === "showPdf" && !isCallbackFunctionPresent(typeOfCallback)) {
			typeOfCallback = "openInInternalBrowser";
		}
		if (typeOfCallback === "showPdf" && isCallbackFunctionPresent(typeOfCallback) && href.includes('cmd=rechnung')) {
			if(href.indexOf('//') === -1) {
				href =`${window.location.protocol}//${window.location.hostname}${href}`;
			}
			const urlOriginal = new URL(href);
			urlOriginal.searchParams.delete("cmd");
			callbackUrl = urlOriginal.toString();
		}
		if ((typeOfCallback === "showPdf") && isCallbackFunctionPresent(typeOfCallback)) {
			callbackMethod = "showPdf";
		}
		const hostname = window.location.hostname;
		const browserProtocol = window.location.protocol;
		if (absoluteUrl === true) {
			if (!(callbackUrl.indexOf("http:") === 0 || callbackUrl.indexOf("https:") === 0)) {
				callbackUrl = `${browserProtocol}//${hostname}${callbackUrl}`;
			}
			callbackUrl = `url=${encodeURIComponent(callbackUrl)}`;
		}
		callbackString = `${protocol}://${callbackMethod}?${callbackUrl}`;
	}
	return callbackString;
}
