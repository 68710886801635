/* eslint-disable compat/compat */
import pageObject from "../objects/PageObject";

pageObject.register(identifyCampaigns);
pageObject.register(identifyGoogleAds);
pageObject.register(trackQueryParams);
pageObject.register(trackSeite);

const adsParameters = [
  'gclid',
  'dclid',
  'nclid'
];

const utmParameters = [
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_content',
  'utm_id',
  'utm_term',
];

const allHandledParameters = adsParameters.concat(utmParameters);

function identifyGoogleAds() {
  return new Promise((resolve, reject) => {
    try {
      const params = new URLSearchParams(location.search);
      const data = {
        ads: {}
      };
      adsParameters.forEach((item) => {
        if (params.has(item) === true) {
          const name = item;
          data.ads[name] = params.get(item);
        }
      });
      resolve(data);
    } catch (e) {
      reject(
        new Error(e)
      );
    }
  });
}

function identifyCampaigns() {
  return new Promise((resolve, reject) => {
    try {
      const params = new URLSearchParams(location.search);
      const data = {
        campaign: {}
      };
      utmParameters.forEach((item) => {
        if (params.has(item) === true) {
          const name = item.substr(4, item.length)
          data.campaign[name] = params.get(item);
        }
      });
      resolve(data);
    } catch (e) {
      reject(
        new Error(e)
      );
    }
  });
}

function trackSeite() {
	return new Promise((resolve, reject) => {
		try {
			const result = {
				seite: window.location.pathname
			}
			resolve(result);
		} catch(e) {
			reject(
				new Error(e)
			);
		}
	});
}

function trackQueryParams() {
  return new Promise((resolve, reject) => {
    try {
      const params = new URLSearchParams(location.search);
      const query = [];
      params.forEach((value, key) => {
        if(!allHandledParameters.includes(key)) {
          query.push(`${key}=${value}`);
        }
      });
      const result = {
        queryString: query.join("&")
      };
      resolve(result);
    } catch (e) {
      reject(
        new Error(e)
      );
    }
  });
}
