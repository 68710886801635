export { initQuickLizard };

async function initQuickLizard(model) {
	if(model["base-mandant"] !== 2 && model["base-mandant"] !== 4 && model["base-mandant"] !== 5) {
		return;
	}
	if(typeof(model["products"]) === "undefined") {
		return;
	}
	if (model["seitentyp"] === "produkt" && model["preisbindung"] === false) {
		const payload = trackAds(model["products"][0], model["ean"]);
		await callRestApi(payload, model["stage"], model["base-mandant"]);
	}
	if (model["seitentyp"] === "dankeseite") {
		const payload =  trackPurchase(model["products"]);
		if(payload.length > 0) {
			await callRestApi(payload, model["stage"], model["base-mandant"]);
		}
	}
}

/*
{
	"client_key": "YOUR_CLIENT_KEY", //your QL client key
	"price": 123.41, //product shelf price as float
	"product_id": "123-AA-X23", //product identifier string
	"event": "view", //event name - "view" represents a product page view
	"permalink": "https://yourstore.com/123-AA-X23/", // product URL on your store
	"vid": "DASDAS-#CCCC-NA", //optional anonymous user identifier
	"tags": ["user:new"], //optional tags for this evening
	"channels": ["online"] //optional pricing channels for this event
}
*/
function trackAds(product, ean) {
	const payload = [
		{
			product_id: ean.toString(),
			price: parseFloat(product.price),
			event: "view",
			permalink: window.location.href
		}
	];
	return payload;
}

/*
{
  "client_key": "YOUR_CLIENT_KEY", //your QL client key
  "price": 123.41, //product sale price as float
  "product_id": "123-AA-X23", //product identifier string
  "event": "conversion", //event name - "view" represents a product page view
  "vid": "DASDAS-#CCCC-NA", //optional anonymous user identifier
  "tags": ["user:new"], //optional tags for this evening
  "channels": ["online"], //optional pricing channels for this event
  "units": 3 // optional number of sold units
}
*/
function trackPurchase(products) {
	const filteredPayload = products.filter((element) => {
		if(element.preisbindung === true) {
			return false;
		}
		return true;
	});
	const payload = filteredPayload.map((element) => {
		const units = parseFloat(element.quantity, 10);
		return {
			product_id: element.ean,
			price: parseFloat(element.price, 10) * units,
			units: units,
			event: "conversion",
		}
	});

	return payload;
}

async function callRestApi(payload, stage, baseMandant) {
	if(shouldRequestBeBlocked(navigator.userAgent)) {
		return;
	}
	const basePayload = {
		client_key: "thaliade"
	};
	if(stage === "produktion") {
		switch(baseMandant) {
			case 4:
				basePayload.channels = ["thaliaat"];
				break;
			case 5:
				basePayload.channels = ["bol"];
				break;
			default:
				basePayload.channels = ["default"];
		}
	} else {
		basePayload.channels = ["thaliastaging"];
	}
	const combinedPayload = payload.map((element) => {
		return Object.assign({}, basePayload, element);
	});

	// REST API: https://evt.quicklizard.com/events
	// Wir schicken die Events an /proxy/lizard. Im Apache findet sich eine Weiterleitungsregel an https://evt.quicklizard.com/events
	const url = `/proxy/lizard`;
	const config = {
		method: 'POST',
		cache: 'no-store',
		body: JSON.stringify(combinedPayload)
	};
	await fetch(url, config);
}

function shouldRequestBeBlocked(useragent) {
	return /bot|googlebot|crawler|spider|robot|crawling/i.test(useragent)
}
