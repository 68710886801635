export default class ScriptLoader {
	constructor(src, async = true) {
		this.src = src;
		this.type = "text/javascript";
		this.container = document.head;
		this.async = async;
		this.script = this.createDomElement();
	}

	async load() {
		return new Promise(async (resolve, reject) => {
			this.container.insertAdjacentElement("beforeend", this.script);
			try {
				await this.scriptIsLoaded(this.script);
				resolve();
			} catch (error) {
				reject(error);
			}
		})
	}

	createDomElement() {
		const script = document.createElement("SCRIPT");
		script.type = this.type;
		script.src = this.src;
		if (this.async) {
			script.async = true;
		}
		return script;
	}

	scriptIsLoaded() {
		return new Promise((resolve, reject) => {
			this.script.addEventListener("load", () => {
				resolve();
			})
			this.script.addEventListener("error", (event) => {
				const error = new Error(`[asset-service|ScriptLoader] Script konnte nicht geladen werden: ${this.src}`);
				reject(error);
			})
		});
	}
}
