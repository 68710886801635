export default {
	"name": "DigitalChat",
	"stage": "integration",
	"mandanten": ["2"],
	"pagetypes": "all",
	"seitenPattern": /(\/vorteile\/abo\/skoobe|\/ereader\/tolino|\/hoerbuch-download\/dauerformate\/hoerbuch-download-abo)/g,
	"serviceSettings": {
		"jspUrl": "https://thalia-p-public.novomind.cloud/chatRest/%27",
		"frontendId": "frontend-digital",
		"scriptSrc": "https://ecdn.novomind.com/rules/la/nm.rules.js"
	}
}
