/* eslint-disable no-console */
import pageObject from "../../objects/PageObject";
import cartObject from "../../objects/CartObject";
import purchaseObject from "../../objects/PurchaseObject";
import purchaseProductsObject from "../../objects/PurchaseProductsObject";
import checkoutObject from "../../objects/CheckoutObject";
import checkoutProductsObject from "../../objects/CheckoutProductsObject";
import * as dtmApi from "../../datalayer-api";
import { buildPurchaseTrackingData } from "./purchase-tracking";
import { buildCheckoutTrackingData } from "./checkout-tracking";

let useBeforeUnloadEvents = true;
if (isLegacyBrowser()) {
  useBeforeUnloadEvents = false;
}

function isLegacyBrowser() {
  if (Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject) {
      return true;
  }
  return false;
}

window.addEventListener("load", performDefaultPageTracking);
if (useBeforeUnloadEvents === true) {
  window.addEventListener("beforeunload", performDefaultPageTracking);
}

async function performDefaultPageTracking() {
  window.removeEventListener("load", performDefaultPageTracking, false);
  if (useBeforeUnloadEvents === true) {
    window.removeEventListener("beforeunload", performDefaultPageTracking, false);
  }
  const [
    page,
    cart,
    purchase,
    purchaseProduct,
    checkout,
    checkoutProduct
  ] = await Promise.all([
    pageObject.getData(),
    cartObject.getData(),
    purchaseObject.getData(),
    purchaseProductsObject.getData(),
    checkoutObject.getData(),
    checkoutProductsObject.getData()
  ]);

  const checkoutDataLayer = buildCheckoutTrackingData(
    checkout,
    checkoutProduct
  );

  const purchaseDataLayer = buildPurchaseTrackingData(
    purchase,
    purchaseProduct
  );

  try {
    const trackingData = Object.assign({}, page, cart, purchaseDataLayer, checkoutDataLayer);
    trackingData.event = "page";
    dtmApi.sendData(trackingData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`page-tracking error: ${err}`);
  }
  return;
}
