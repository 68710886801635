import { dlEventHandler, dlEventAttributeIsPresent } from "../dl-event";

document.addEventListener("copy", trackCopy);

export function trackCopy(event) {
	const { target } = event;
	const dlEventAttribute = dlEventAttributeIsPresent(target);
	if (dlEventAttribute) {
		return dlEventHandler(event, {
			content: window.getSelection().toString()
		});
	}
}
