import * as dtmApi from "../../datalayer-api";

const allowedOrigins = [
  "https://nomad-audiobook-stage-open.skoobe.net",
  "https://nomad-prod-open.skoobe.net"
];

// Tracking auf Grund von Fehlern ausgeschaltet - Rücksprache mit Nathalie Jung
window.addEventListener('message', trackSkoobe);

async function trackSkoobe(event) {
  if (!allowedOrigins.includes(event.origin)) {
    return;
  }
  if (typeof (event.data.eventCategory) === "undefined" || event.data.eventCategory !== "skoobe-checkout") {
    return;
  }
  if (typeof (event.data.eventAction) === "undefined") {
    return;
  }

  try {
    const trackingData = {
      event: "gaEvent",
      eventCategory: "skoobe-checkout",
      eventAction: event.data.eventAction || "unbekannt",
      eventLabel: event.data.Label || ""
    };
    dtmApi.sendData(trackingData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`submit-tracking error: ${err}`);
  }
}

/*
const data = {
  category: "skoobe-checkout",
  action: "click-open"
};
// die Ziel-Domain speichern
const domain = "*";
thaliaWindow.postMessage(data, domain);
*/
