import ScriptLoader from "../../../../helpers/ScriptLoader";

export async function initSalesforcePersonalizations(dps) {
	try {
		await loadPersonalizations(dps);
	} catch (error) {
		logError(error, dps)
	}
}

export async function loadPersonalizations(dps) {
	const { scriptSrc } = dps.serviceSettings;
	const loader = new ScriptLoader(scriptSrc, false);
	try {
		return await loader.load();
	} catch (error) {
		throw error;
	}
}

export function logError(error, dps) {
	const { stage } = dps;
	console.debug(`Salesforce Personalizations konnte für die Stage ${stage} nicht geladen werden. Fehler:`);
	console.error(error);
}
