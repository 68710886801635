import * as dtmApi from "../../../datalayer-api";
import productObject from "../../../objects/ProductObject";
import { registerClickTracker } from "./click-tracking";

export { performTracking, createProductClickEvent };

registerClickTracker(isResponsible, performTracking);

function isResponsible(element) {
	const elementWithId = !!(element.querySelector("[product-id]") || element.closest("[product-id]"));
	// wenn das geklickte Element ein interaction-Attribut hat (oder in einem liegt), soll es nicht per Product-Klick-Tracking behandelt werden
	const elementHasInteraction = !!(element.querySelector("[interaction]") || element.closest("[interaction]"));
	if (elementWithId && !elementHasInteraction) {
		return true;
	}
	return false;
}

async function performTracking(element) {
	const elementWithId = element.querySelector("[product-id]") || element.closest("[product-id]");
	const elementWithBasketAddAttribute = !!(element.querySelector("[basket=add]") || element.closest("[basket=add]"));
	const elementWithBasketRemoveAttribute = !!(element.querySelector("[basket=remove]") || element.closest("[basket=remove]"));
	let action = elementWithBasketAddAttribute ? 'add' : 'click';
	action = elementWithBasketRemoveAttribute ? 'remove' : action;
	const trackingData = await productObject.getData(elementWithId);
	if (Object.keys(trackingData).length === 0) {
		return;
	}
	trackingData.action = action;
	try {
		dtmApi.sendData(createProductClickEvent(trackingData));
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(`click-tracking error: ${err}`);
	}
}

function createProductClickEvent(data) {
	const returnObject = {
		event: "EECproductClick",
		ecommerce: {
			click: {
				action: data.action,
				actionField: {
					list: data.actionList.listName
				},
				products: [data],
				...data.actionList.trackingAttributes,
				...data.actionList.customProperties
			}
		},
	};
	// nur bei "click" dürfen wir die Produktliste hinzufügen
	if (data.action !== 'click') {
		delete returnObject.ecommerce.click.actionField;
		returnObject.ecommerce.click.products.forEach(product => {
			product.quantity = 1;
			delete product.actionList.listName;
		});
	}

	return returnObject;
}
