/* eslint-disable compat/compat */
import isEqual from "deep-equal";
import * as dtmApi from "../../../datalayer-api";
import promotionObject from "../../../objects/PromotionObject";

const queue = [];
const queueSize = 50;
const intersectionThreshold = 0.8;
let useBeforeUnloadEvents = true;
if (isLegacyBrowser()) {
  useBeforeUnloadEvents = false;
}

function isLegacyBrowser() {
  if (Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject) {
      return true;
  }
  return false;
}

const intObserver = new IntersectionObserver(
  entries =>
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        performPromotionTracking(entry.target);
      }
    }),
  { threshold: intersectionThreshold }
);

const config = { childList: true, subtree: true };
const mutObserver = new MutationObserver(mutations =>
  mutations.forEach(mutation => {
    if (mutation.type === "childList") {
      mutation.addedNodes.forEach(node => handleObservation(node));
      mutation.removedNodes.forEach(node => handleObservation(node, false));
    }
  })
);

document.addEventListener("DOMContentLoaded", () => {
  mutObserver.observe(document.body, config);
  handleObservation(document.body);
});

if(useBeforeUnloadEvents === true) {
  window.addEventListener("beforeunload", () => {
    if (!queueSizeReached()) {
      dtmApi.sendData(createEventFromQueue(queue.length % queueSize));
    }
      return;
  });
}

function handleObservation(element, observe = true) {
  // 1. check mutation element child nodes
  // mutObserver finds some nodes, that do not have querySelectorAll
  if (element.querySelectorAll) {
    element
    .querySelectorAll(
      "[impression=promotion] [promotion-creative], [impression=promotion][promotion-creative]"
    )
    .forEach(product => initIntObserver(product, observe));
  }
  // 2. check mutation element itself
  if (element instanceof HTMLElement && isValidPromotionTracking(element)) initIntObserver(element, observe);
}

function initIntObserver(product, observe) {
    if (observe) {
      intObserver.observe(product);
    } else {
      intObserver.unobserve(product);
    }
}

async function performPromotionTracking(target) {
  try {
    const trackingData = await promotionObject.getData(target);
    if (Object.keys(trackingData).length === 0) {
      return;
    }
    if (isNotInQueue(trackingData)) {
      queue.push(trackingData);
      if (queueSizeReached()) {
        dtmApi.sendData(createEventFromQueue());
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`promotion-tracking error: ${err}`);
  }
}

function createEventFromQueue(impressionsCount = queueSize) {
  return {
    event: "EECpromotionImpression",
    nonInteraction: true,
    ecommerce: {
      promoView: {
        promotions: queue.slice(queue.length - impressionsCount)
      }
    }
  };
}

function queueSizeReached() {
  return queue.length % queueSize === 0;
}

function isNotInQueue(trackingData) {
  return !queue.some(data => isEqual(trackingData, data));
}

function isValidPromotionTracking(element) {
    return element.getAttribute("impression") === "promotion" && element.hasAttribute("promotion-creative");
}
