import { sendData } from "../../datalayer-api";
import { buildClickData } from "../click/click";

document.addEventListener("change", changeHandler);

function changeHandler(event) {
	const { target } = event;
    const validFormTracking = isValidForFormTracking(target);
    if(validFormTracking) {
        const data = buildClickData(target);
		return sendData(data);
    }
}

export function isValidForFormTracking(target) {
    return target.tagName === "SELECT" ||
      (target.tagName === "INPUT" && target.type === "radio") ||
      (target.tagName === "INPUT" && target.type === "checkbox");
}

