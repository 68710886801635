import { DataLayerCustomProperties } from "./DataLayerCustomProperties";
import { DataLayerItem } from "./DataLayerItem";
import { isProductAttributionRequired } from "../product-attribution/productAttribution";

export class DataLayerEntry {
	constructor(eventName, dataElement, baseParams = {}) {
		this.eventName = eventName;
		this.dataElement = dataElement;
		this.baseParams = baseParams;
		this.productAttributionRequired = isProductAttributionRequired(this.eventName);
	}

	get component() {
		const component = this.dataElement.closest("[component]");
		if (!component) {
			return null;
		}
		return component;
	}

	build() {
		const eventData = {};
		eventData["event"] = this.eventName;
		eventData["attributes"] = this.getDlEventAttributes();
		const dlProducts = this.getDlEventProducts();
		if (dlProducts) {
			eventData["products"] = this.getDatalayerItems(dlProducts);
		}
		return eventData;
	}

	getDlEventAttributes() {
		const attributes = {};
		const dataElementAttributes = this.getDataElementAttributes();
		const componentAttributes = this.getComponentAttributes();
		Object.assign(attributes, {
			...new DataLayerCustomProperties(dataElementAttributes).build(),
			...new DataLayerCustomProperties(componentAttributes).build(),
			...this.baseParams
		})
		return attributes;
	}

	getDatalayerItems(dlProducts) {
		const items = [];
		for (const product of dlProducts) {
			const item = new DataLayerItem(product, this.component, this.productAttributionRequired).build();
			items.push(item)
		}
		return items;
	}

	getDlEventProducts() {
		const dlProducts = this.dataElement.querySelectorAll("dl-product");
		const isEmpty = isEmptyArray(dlProducts);
		if (!isEmpty) {
			return dlProducts;
		}
		const dataElementHasProductId = this.dataElement.hasAttribute("product-id");
		if (dataElementHasProductId) {
			return [this.dataElement];
		}
		return null;
	}

	getDataElementAttributes() {
		if (!this.dataElement || this.dataElement.tagName.toLowerCase() === "dl-product") {
			return [];
		}
		return Array.from(this.dataElement.attributes);
	}

	getComponentAttributes() {
		if (!this.component) {
			return [];
		}
		return Array.from(this.component.attributes);
	}

}

function isEmptyArray(array) {
	return array && array.length === 0;
}
