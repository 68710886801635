import { sendData } from "../../datalayer-api";
export { createDialogViewEvent };

window.addEventListener('DOMContentLoaded', observeAllDialogs);

function observeAllDialogs() {
  const config = {
    attributes: true,
    childList: true,
    subtree: true
  };
  const observer = new MutationObserver((mutations) => {
    mutations.forEach(mutation => {
      const mutationOccuredOnOpenDialog = mutation.target && mutation.target.nodeName === "DIALOG" && mutation.target.open === true;
      const mutationOccuredOnCloseDialog = mutation.target && mutation.target.nodeName === "DIALOG" && mutation.target.open === false;
      const observedAttributes = ["open", "dl-name"];
      if (mutationOccuredOnOpenDialog && observedAttributes.includes(mutation.attributeName)) {
        handleOpenEvent(mutation.target);
      }
      if (mutationOccuredOnCloseDialog && observedAttributes.includes(mutation.attributeName)) {
        handleCloseEvent(mutation.target);
      }
    })
  });
  observer.observe(document.body, config);
}

function handleOpenEvent(dialog) {
  const data = createDialogViewEvent(dialog);
  sendData(data);
}

function handleCloseEvent(dialog) {
  const data = createDialogCloseEvent(dialog);
  sendData(data);
}

function createDialogViewEvent(dialog) {
  return {
    event: "dialog_view",
    attributes: {
      dialog_name: dialog.getAttribute("dl-name"),
      component: dialog.closest("[component]")?.getAttribute("component")
    }
  };
}

function createDialogCloseEvent(dialog) {
  return {
    event: "dialog_close",
    attributes: {
      dialog_name: dialog.getAttribute("dl-name"),
      component: dialog.closest("[component]")?.getAttribute("component")
    }
  };
}
