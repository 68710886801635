import { getCurrencyCode } from "../../helpers/currencyCodeHelper"

export {
	getProductDataFromElement,
	getAllProductData,
	getCheckoutData,
	removeLegacyTrackingPrefixes
 };


function getProductDataFromElement(productElement, compatible = false) {
	const actionListElement = productElement.closest("[product-list]");
	const component = productElement.closest("[component]")?.getAttribute("component") || "";
	const listName = actionListElement ? actionListElement.getAttribute("product-list") : component;
	const { trackingAttributes, productData, customProperties, attributes } = getProductDataFromProductElement(productElement);

	const data = {
		component,
		attributes
	};

	if (compatible === true) {
		data.actionList = { listName, customProperties, trackingAttributes };
	}
	const result = Object.assign({}, productData, data);
	return result;
}
function getProductDataFromProductElement(productElement) {
	const productData = getProductAttributes(productElement);
	const trackingAttributes = getAttributesWithPrefix('tracking', productElement);
	const cdAttributes = getAttributesWithPrefix('cd', productElement);
	const cmAttributes = getAttributesWithPrefix('cm', productElement);
	const customProperties = Object.assign({}, cdAttributes, cmAttributes);

	const attributes = Object.assign({}, trackingAttributes, cdAttributes, cmAttributes);
	return { trackingAttributes, productData, customProperties, attributes };
}
function getProductAttributes(element) {
	const productObject = {
		category: "",
		id: "",
		list: "",
		name: "",
		position: "",
		price: "",
		quantity: "",
		variant: "",
	};
	const productAttributes = getAttributesWithPrefix("product", element);
	const result = Object.assign({}, productObject, productAttributes);
	return result;
}
function getAttributesWithPrefix(prefix, element) {
	const searchPrefix = `${prefix}-`;
	const elementAttributes = Array.from(element.attributes)
		.filter(attr => attr.name.startsWith(searchPrefix))
		.reduce((accu, attr) => ({ ...accu, [attr.name.substring(searchPrefix.length)]: attr.value }), {});

	const actionListElement = element.closest("[product-list]");
	const componentElement = element.closest("[component]") || element;
	const parentAttributes = Array.from((actionListElement ? actionListElement : componentElement).attributes)
		.filter(attr => attr.name.startsWith(searchPrefix))
		.reduce((accu, attr) => ({ ...accu, [attr.name.substring(searchPrefix.length)]: attr.value }), {});

	const result = Object.assign({}, elementAttributes, parentAttributes);
	return result;
}

function getAllProductData(element) {
	const data = Array.from(element.querySelectorAll("dl-product, dl-checkout-product, dl-purchase-product")).map(getProductData)
	return data;
}

function getProductData(element) {
	const productData = getProductDataFromElement(element);
	const legacyAttributes = getLegacyAttributes(element);
	const result = Object.assign({}, productData, legacyAttributes);
	result.currency = getCurrencyCode(window.location);
	return result;
}

function getLegacyAttributes(element) {
	return Array.from(element.attributes)
	.filter(filterForLegacyAttributes)
	.reduce((accu, attr) => ({ ...accu, [attr.name]: attr.value }), {});
}

function filterForLegacyAttributes(attr) {
	return !attr.name.startsWith('product-') && !attr.name.startsWith('tracking-') && !attr.name.startsWith('cm-') && !attr.name.startsWith('cd-');
}

function getCheckoutData(element) {
	const result = element.getAttributeNames()
		.filter(attr => !attr.startsWith('component'))
		.reduce((accu, name) => ({ ...accu, [name]: element.getAttribute(name) }), {});
	return result;
}

const legacyTrackingPrefixes = ["product-", "tracking-", "cd-", "cm-"];
function removeLegacyTrackingPrefixes(name) {
	const hasLegacyTrackingPrefix = legacyTrackingPrefixes.some(prefix => name.includes(prefix));
	if (hasLegacyTrackingPrefix) {
		return name.split("-")[1];
	}
	return name;
}
