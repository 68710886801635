/* eslint-disable compat/compat */
import optionObject from "../objects/OptionObject";

optionObject.register((target) => {
	return new Promise((resolve, reject) => {
		const component = target.closest("[component]");
		if (!component) {
			reject(
				new Error(
					`Cannot get tracking data from ${target.outerHTML} \nThe element should be inside of an element with an [component] attribute.`
				)
			);
		}

		const category = component.getAttribute("component");
		const action = getAction(target);
		const label = getLabel(target);

		let data = {};

		if (action && label) {
			data = {
				eventCategory: category,
				eventAction: action,
				eventLabel: label,
			};
		} else {
			new Error(
				`Cannot get tracking data from ${target.outerHTML} \nThe element should contain [name] and [value] attribute.`
			);
		}
		resolve(data);
	});
});

function getAction(target) {
	return target.closest("[interaction]")
	? target.closest("[interaction]").getAttribute("interaction")
	: target.name;
}

function getLabel(target) {
	const isSelectElement = target.nodeName === "SELECT";
	if (isSelectElement) {
		const selectedOption = target.querySelector("option:checked");
		return getLabelValue(selectedOption);
	}
	return getLabelValue(target);
}

function getLabelValue(target) {
	return (
		target.getAttribute("caption") ||
		target.value ||
		target.getAttribute("value") ||
		getLabelFromStatus(target)
	);
}

function getLabelFromStatus(target) {
	return target.checked ? "checked" : "unchecked";
}
