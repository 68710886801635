import { performClickTracking } from "./default-click-tracking";
import { isValidComponent, isValidForClickTracking, getClickTarget } from "../../click/default";
import { dlEventAttributeIsPresent } from "../../dl-event";

export { registerClickTracker };

const clickTracker = [];

function registerClickTracker(responsibilityCallback, trackingCallback) {
  clickTracker.push({
    responsibilityCallback,
    trackingCallback
  });
}

document.addEventListener("click", ({ target }) => {
  const validcomponent = isValidComponent(target);
  if (!validcomponent) return;
  // Do nothing if [dl-event] Attribut is present at click target --> see "shell/datalayer/events/click/dl-event.js"
  const dlEventAttribute = dlEventAttributeIsPresent(target);
  if (dlEventAttribute) return;

  const element = isValidForClickTracking(target) ? target : getClickTarget(target);

  if (element) {
    const responsibleTrackers = clickTracker.filter(tracker =>
      tracker.responsibilityCallback(element)
    );
    if (responsibleTrackers.length > 0) {
      // TODO klären, ob es mehrere Events geben kann
      responsibleTrackers[0].trackingCallback(element);
    } else {
      performClickTracking(element);
    }
  }
});

document.addEventListener("change", ({ target }) => {
  const element = isValidForFileuploadTracking(target) ? target : false;

  if (element) {
    performClickTracking(element);
  }
});

function isValidForFileuploadTracking(target) {
  return (target.tagName === "INPUT" && target.type === "file");
}
