import * as dtmApi from "../../datalayer-api";
import submitObject from "../../objects/SubmitObject";
import { addEventHandler, EVENTS } from "./customEventTrigger";

addEventHandler(EVENTS.FORM_SUBMIT, (element) => {
	return trackSubmit(element);
});

async function trackSubmit(element) {
	if (typeof element === "undefined") {
		return;
	}

	const formData = await submitObject.getData(element);
	if (Object.keys(formData).length === 0) {
		return;
	}
	const trackingData = {
		event: "gaEvent",
		eventAction: "submit-form",
	};
	const ga4TrackingData = {
		event: "form",
	};
	try {
		trackingData.eventCategory = formData.component;
		ga4TrackingData.component = formData.component;
		if (typeof formData.errorFields !== "undefined") {
			console.warn('Deprecated: Die Übergabe von "errorFields" am Submit-Event sollte nicht mehr verwendet werden.');
			trackingData.eventLabel = formData.errorFields.join(",");
		} else if (typeof formData.success === "string") {
			trackingData.eventLabel = formData.success;
			ga4TrackingData.success = formData.success;
		} else if (typeof formData.error === "string") {
			trackingData.eventLabel = formData.error;
			ga4TrackingData.error = formData.error;
		} else {
			ga4TrackingData.success = "erfolgreich";
			trackingData.eventLabel = "erfolgreich";
		}
		dtmApi.sendData(trackingData);
		dtmApi.sendData(ga4TrackingData);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(`submit-tracking error: ${err}`);
	}
}
