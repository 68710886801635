import { register } from "./error-logging";

class SentryConfig extends HTMLElement {

	connectedCallback() {
		const sentryPlugin = {
			name: this.projectName,
			release: `${this.projectName}@${this.releaseVersion}`,
			dsn: `https://${this.projectDsn}@sentry.thalia.de/${this.projectId}`,
			filenameRegex: this.generateRegexArray(this.projectFiles)
		};
		register(sentryPlugin);
	}

	generateRegexArray(projectFiles) {
		const regexArray = [];
		projectFiles.split(",").forEach(entry => {
			regexArray.push(
				new RegExp(entry.trim())
			)
		});
		return regexArray;
	}

	get projectName() {
		return this.getAttribute("project-name");
	}

	get projectDsn() {
		return this.getAttribute("project-dsn");
	}

	get projectId() {
		return this.getAttribute("project-id");
	}

	get projectFiles() {
		return this.getAttribute("project-files");
	}

	get releaseVersion() {
		return this.getAttribute("release-version");
	}

}

if (!customElements.get("sentry-config")) {
	customElements.define("sentry-config", SentryConfig);
}
