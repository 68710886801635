/* eslint-disable no-console */
import "data-layer-helper/dist/data-layer-helper";

export {
  registerDatalayerListener
};

function registerDatalayerListener(listener, listenToPast = false) {
  if (typeof window.newDataLayer === "undefined") {
    console.warn("datalayer is undefined, listener not registered");
    return false;
  }
  // eslint-disable-next-line no-undef
  new DataLayerHelper(window.newDataLayer, listener, listenToPast);
  return true;
}
