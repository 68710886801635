import { getAppEventDatalayer } from "./call-backs-app";

class AppCallback extends HTMLElement {
	connectedCallback() {
		try {
			const url = this.getAttribute('src') || this.getAttribute('data-src') || '';
			const callback = this.getAttribute('callback') || '';
			const params = this.getAttribute('params') || '';
			const redirectAfterCallback = this.getAttribute('redirect-after-callback') || '';
			const searchParamsObject = new URLSearchParams(params);

			const paramElements = this.querySelectorAll('callback-param');
			paramElements.forEach((element) => {
				const key = element.getAttribute('key');
				const value = element.getAttribute('value');
				if(searchParamsObject.has(key)) {
					const oldValue = searchParamsObject.get(key);
					searchParamsObject.set(key, `${oldValue};${value}`);
				} else {
					searchParamsObject.append(key, value);
				}
			})

			//split & join statt replaceAll, weil der IE11 das nicht kann
			const paramString = "" + searchParamsObject.toString().split('%3B').join(';');

			const datalayer = getAppEventDatalayer();
			datalayer.push({ url: url, callback: callback, params: paramString});
			if(redirectAfterCallback !== "") {
				try {
					window.location.assign(redirectAfterCallback);
				} catch(e) {
					console.warn('Konnte nicht weiterleiten');
				}
			}
		} catch (e) {
			return false;
		}
	}
}

document.addEventListener('DOMContentLoaded', (event) => {
	if (!customElements.get('callback-app')) {
		customElements.define('callback-app', AppCallback);
	}
  })

