import { isEmptyObject } from "./trackingUtils";
export { buildPurchaseTrackingData };

function buildPurchaseTrackingData(purchase, products) {
    if (isEmptyObject(purchase)) {
        return {};
    }
    const trackingData = {
        ecommerce: {
            currencyCode: purchase.currencyCode ? purchase.currencyCode : "",
            purchase: {
                actionField: {
                    id: purchase.id,
                    affiliation: purchase.affiliation,
                    revenue: purchase.revenue,
                    shipping: purchase.shipping,
                    coupon: purchase.coupon
                },
                products
            }
        }
    };

    return { ...trackingData, ...filterExistingAttributes(purchase, trackingData) };
}

function filterExistingAttributes(purchase, trackingData) {
    return Object.keys(purchase)
        .filter( // filter undefined keys
            key => typeof purchase[key] !== "undefined")
        .filter( // filter attributes from actionField
            key => !Object.keys(trackingData.ecommerce.purchase.actionField).includes(key))
        .filter( //  filter attributes from ecommerce
            key => key !== "currencyCode")
        .reduce(
            (accu, key) => ({ ...accu, [key]: purchase[key] }), {});
}
