export { initThaliaChat };

function initThaliaChat(dps) {
	const { jspUrl, frontendId, scriptSrc } = dps.serviceSettings;

	window.NM = window.NM || {};
	window.NM.o = {
		jspURL: jspUrl,
		frontendID: frontendId
	};
	const script = document.createElement("script");
	script.src = scriptSrc;
	script.type = "text/javascript";
	script.async = 1;
	document.getElementsByTagName("head")[0].appendChild(script);
}
