/* eslint-disable no-console */
/* eslint-disable compat/compat */
import submitObject from "../objects/SubmitObject";
import { trigger, EVENTS } from "../events/tracking/customEventTrigger";

class DataLayerSubmit extends HTMLElement {

	connectedCallback() {
		this.initSubmitObject();
		submitObject.register((target) => { return this.getSubmitObject(target); });
		trigger(EVENTS.FORM_SUBMIT, this);

	}

	initSubmitObject() {
		this.error = this.getAttribute('error') || undefined;
		this.success = this.getAttribute('success') || undefined;
		this.result = {};
		if (typeof (this.error) !== 'undefined' && this.error !== '´string') {
			this.result.error = this.error;
		} else if (typeof (this.success) !== 'undefined' && this.success !== '') {
			this.result.success = this.success;
		}
	}

	getSubmitObject(target) {
		if (target !== this) {
			return this.getPromiseWithObject({});
		}
		this.resetSubmitElement();
		return this.getPromiseWithObject(this.result);
	}

	resetSubmitElement() {
		this.setAttribute('done', 'true');
		this.initSubmitObject();
	}

	getPromiseWithObject(data) {
		return new Promise((resolve, reject) => {
			try {
				resolve(data);
			} catch (error) {
				console.log(`dl-submit: getPromiseWithObject: reject`, error);
				reject(error);
			}
		})
	}

}

if (!customElements.get('dl-submit')) {
	customElements.define('dl-submit', DataLayerSubmit);
}
