export function getReferrer() {
	const referrer = document.referrer;
	if (referrer !== "") {
		const hostname = getHostnameIfValid(referrer, location.hostname);
		if (hostname !== "") {
			return hostname;
		}
	}
	return null;
}

function getHostnameIfValid(givenUrl, currentHostname) {
	// eslint-disable-next-line compat/compat
	const hostname = new URL(givenUrl).hostname;
	return (hostname.indexOf(currentHostname) === -1) ? hostname : "";
}
