import { DataLayerItem } from "../../DataLayerItem";

export default buildViewItemsData;

function buildViewItemsData(viewedItems) {
	const eventData = {
		event: "view_items"
	}
	const views = [];
	for (const item of viewedItems) {
		const itemComponent = item.closest("[component]");
		const itemData = new DataLayerItem(item, itemComponent, false, true).build();
		views.push(itemData);
	}
	eventData['views'] = getItemsAssignedByCategory(views);
	return eventData;
}

function getItemsAssignedByCategory(data) {
	return data.reduce((acc, item) => {
		const list = item['list'] ? item['list'] : item['component'];
		if (!acc[list]) {
			acc[list] = [];
		}
		acc[list].push(item);
		return acc;
	}, {})
}
