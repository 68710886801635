// Siehe Fehler: https://thaliamayersche.atlassian.net/browse/WDD-1491
// https://docs.usercentrics.com/#/consent-mode?id=step-2-optional-use-the-usercentrics-cmp-events-to-signal-the-consent-status-via-the-consent-mode-api-for-custom-data-processing-services
import getWebAnalyticsContainer from "../analytics/analytics-container";
import { sendData } from "../datalayer/datalayer-api";

const UC_ANALYTICS_DPS_NAME = "Google Analytics";
const UC_ADS_DPS_NAME = "Google Ads";
export const CONSENT_UPDATE_EVENT_NAME = "como_update";

export function ucEventHandler(event) {
	if (!event.detail) return;
	const { detail } = event;
	if (detail["event"] !== "consent_status") return;
	const adsExists = detail.hasOwnProperty(UC_ADS_DPS_NAME);
	const analyticsExists = detail.hasOwnProperty(UC_ANALYTICS_DPS_NAME);
	const ucAdServiceGranted = detail[UC_ADS_DPS_NAME];
	const ucAnalyticsServiceGranted = detail[UC_ANALYTICS_DPS_NAME];

	// WDD-1493
	// Wir pushen ein zusätzliches Event in den Datalayer (como_update),
	// das für GA4 Events und GTM Tags als Trigger dient.
	// Erklärung Usercentrics consent_status "type"
	// implicit: Standard Status der Usercentrics DPS (Data Processing Service, i.d.R. alle false)
	// explicit: vom Nutzer gesetzter Status eines (DPS)
	if (detail["type"] === "explicit") {
		sendComoUpdateEvent(ucAdServiceGranted, ucAnalyticsServiceGranted);
	}

	if (adsExists && analyticsExists) {
		return updateAll(ucAdServiceGranted, ucAnalyticsServiceGranted);
	}
	if (adsExists) {
		return updateAds(ucAdServiceGranted);
	}
	if (analyticsExists) {
		return updateAnalytics(ucAnalyticsServiceGranted);
	}
}

export function updateAll(ucAdServiceGranted, ucAnalyticsServiceGranted) {
	gtag("consent", "update", {
		ad_storage: ucAdServiceGranted ? "granted" : "denied",
		ad_user_data: ucAdServiceGranted ? "granted" : "denied",
		ad_personalization: ucAdServiceGranted ? "granted" : "denied",
		analytics_storage: ucAnalyticsServiceGranted ? "granted" : "denied"
	});
}

export function updateAnalytics(ucAnalyticsServiceGranted) {
	gtag("consent", "update", {
		analytics_storage: ucAnalyticsServiceGranted ? "granted" : "denied"
	});
}

export function updateAds(ucAdServiceGranted) {
	gtag("consent", "update", {
		ad_storage: ucAdServiceGranted ? "granted" : "denied",
		ad_user_data: ucAdServiceGranted ? "granted" : "denied",
		ad_personalization: ucAdServiceGranted ? "granted" : "denied"
	});
}

export function gtag() {
	const datalayer = getWebAnalyticsContainer();
	datalayer.push(arguments);
}

export function sendComoUpdateEvent(ucAdServiceGranted, ucAnalyticsServiceGranted) {
	return sendData({
		event: CONSENT_UPDATE_EVENT_NAME,
		services: {
			ad_storage: ucAdServiceGranted ? "granted" : "denied",
			ad_user_data: ucAdServiceGranted ? "granted" : "denied",
			ad_personalization: ucAdServiceGranted ? "granted" : "denied",
			analytics_storage: ucAnalyticsServiceGranted ? "granted" : "denied"
		}
	});
}
