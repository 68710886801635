import { sendData } from "../datalayer-api";
import { getMandantId, getBaseMandantId, getBaseMandantName } from "../basedata/mandant";
import getDeviceSize from "../basedata/device-size";
import { getStage } from "../basedata/stage";
import { getReferrer } from "../basedata/referrer";
import { isEreader } from "../../helpers/ereader/isereader";

const basedata = getBasedata();

if (isEreader) {
	sendData(basedata);
} else {
	document.addEventListener("DOMContentLoaded", (event) => {
		appendPageviewBasedataToDOM(basedata);
	})
}

function getBasedata() {
	const mandantid = getMandantId();
	const basedata = {
		"base-mandant": getBaseMandantId(mandantid),
		"base-name": getBaseMandantId(mandantid),
		"mandant-id": mandantid,
		"mandant-name": getBaseMandantName(mandantid),
		"device-size": getDeviceSize(),
		"stage": getStage(document.location.hostname || ""),
		"seite": window.location.pathname
	}
	const referrer = getReferrer();
	if (referrer) {
		basedata["referrer"] = referrer;
	}
	return basedata;
}

export function appendPageviewBasedataToDOM(basedata) {
	const dlPageview = document.createElement("dl-pageview");
	for (const [key, value] of Object.entries(basedata)) {
		if (!key) {
			continue;
		}
		dlPageview.setAttribute(key, value);
	}
	document.body.appendChild(dlPageview);
}
