const EVENTS = {
  CART_ADD: "cart_add",
  CART_REMOVE: "cart_remove",
  CHECKOUT: "checkout",
  PURCHASE: "purchase",
  FORM_SUBMIT: "form_submit"
};

export { trigger, addEventHandler, EVENTS };

const eventHandler = {};

function trigger(eventName, element) {
  if (eventHandler[eventName]) {
    eventHandler[eventName].forEach(handler => {
      return handler(element);
    });
  }
}

function addEventHandler(eventName, callback) {
  eventHandler[eventName] = eventHandler[eventName]
    ? eventHandler[eventName]
    : [];
  eventHandler[eventName].push(callback);
  return () => {
    const index = eventHandler[eventName].indexOf(callback);
    eventHandler[eventName].splice(index, 1);
  };
}
