import Cookies from "js-cookie";
import { sendComoUpdateEvent, updateAll } from "./ucEventHandler";
import { isApp } from "../datalayer/basedata/mandant";

export function consentHandlerApp() {
	// Übergangslösung, bis Usercentrics in Apps integriert ist
	const mandantIsApp = isApp();
	const consentCookie = Cookies.get('COOKIECONSENT');
	if (mandantIsApp && consentCookie === '1') {
		sendComoUpdateEvent(true, true)
		updateAll(true, true);
	}
}
