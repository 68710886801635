import thaliaChat from "./thalia-chat/data";
import thaliaChatLegacy from "./thalia-chat-prod/data";
import digitalChat from "./digital-chat/data";
import contentsquare from "./contentsquare/data";
import contentsquareInteg from "./contentsquare/data-integ";
import brazeInteg from "./braze/data-integ";
import brazeProd from "./braze/data-prod";
import salesforcePersonalizations from "./salesforce-personalizations/data-prod";
import salesforcePersonalizationsInteg from "./salesforce-personalizations/data-integ"

const servicesData = [];
servicesData.push(
	thaliaChat,
	digitalChat,
	contentsquare,
	contentsquareInteg,
	thaliaChatLegacy,
	salesforcePersonalizations,
	salesforcePersonalizationsInteg,
	brazeInteg,
	brazeProd
);

export default servicesData;
