import { sendData } from "../../datalayer-api";
import buildSelectItemData from "./select_item";
import buildSelectPromotionData from "./select_promotion";
import { buildClickData } from "./click";

export function defaultClickHandler(event) {
	const { target } = event;
	const validcomponent = isValidComponent(target);
	if (!validcomponent) {
		return false;
	};
	const productClickTracking = isProductclickTracking(target);
	if (productClickTracking) {
		const dataElement = getSelectItemDataElement(target);
		const data = buildSelectItemData(dataElement);
		return sendData(data);
	}
	const selectPromotion = isSelectPromotionTracking(target);
	if (selectPromotion) {
		const data = buildSelectPromotionData(target);
		return sendData(data);
	}
	const defaultClickTarget = isValidForClickTracking(target) ? target : getClickTarget(target);
	if (defaultClickTarget) {
		const data = buildClickData(defaultClickTarget);
		return sendData(data);
	}
}

export function isProductclickTracking(element) {
	// TODO: Wollen wir das wirklich so? Beispiel Format-Accordion auf der ADS: <summary> mit [interaction] und <details> enthält Produktliste
	// Wenn das geklickte Element ein interaction-Attribut hat (oder in einem liegt), soll es nicht per Product-Klick-Tracking behandelt werden
	try {
		const basketTracking = isBasketTracking(element);
		const elementWithId = isElementWithId(element);
		const elementHasInteraction = !!(element.querySelector("[interaction]") || element.closest("[interaction]"));
		if (elementWithId && !elementHasInteraction && !basketTracking) {
			return true;
		}
		return false;
	} catch (error) {
		console.error(error);
	}
}

function isElementWithId(element) {
	return !!(
		element.hasAttribute("[product-id]") ||
		element.querySelector("[product-id]") ||
		element.closest("[product-id]")
	);
}

function isSelectPromotionTracking(element) {
	const elementWithImpression = !!(element.querySelector('[impression="promotion"]') || element.closest('[impression="promotion"]'));
	if (elementWithImpression) {
		return true;
	}
	return false;
}

function isBasketTracking(element) {
	return element.hasAttribute("basket");
}

export function getSelectItemDataElement(target) {
	try {
		const dlProduct = target.querySelector("dl-product");
		if (dlProduct) {
			return dlProduct;
		}
		const elementWithId = target.querySelector("[product-id]");
		if (elementWithId) {
			return elementWithId;
		}
		return target;
	} catch (error) {
		console.error(error);
	}
}

export function isValidComponent(target) {
	const component = target.closest('[component]');
	if (!component) return false;
	// Layout Komponenten ausschließen
	const componentNodename = component.nodeName.toLowerCase();
	return componentNodename.indexOf("layout-") === -1;
}

export function isValidForClickTracking(target) {
	return target.tagName === "A" ||
		target.tagName === "BUTTON" ||
		target.tagName === "SUMMARY" ||
		(target.tagName === "INPUT" && target.type === "submit");
}

export function getClickTarget(target) {
	return target.closest("a, button, summary, input[type='submit']");
}
