export function fixBody(state) {
	const { body } = document;
	if (state) {
		body.classList.add("dialog-open");
	} else {
		body.classList.remove("dialog-open");
	}
}

export function bodyIsFixed() {
	return document.body.classList.contains("dialog-open");
}
