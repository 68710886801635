/* eslint-disable compat/compat */
import purchaseObject from "../objects/PurchaseObject";
import purchaseProductsObject from "../objects/PurchaseProductsObject";
import { getAllProductData, getCheckoutData } from "../helper/extract-product-data";
import { sendData } from "../datalayer-api";
import { getProductAttributionData } from "../../product-attribution/productAttribution";

export class DataLayerPurchase extends HTMLElement {

	connectedCallback() {

		purchaseObject.register(() => new Promise((resolve, reject) => {
			try {
				resolve(getCheckoutData(this));
			} catch (error) {
				reject(error);
			}
		}));
		purchaseProductsObject.register(() => new Promise((resolve, reject) => {
			try {
				const data = getAllProductData(this);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		}));

		// für GA4 tracken wir das Event separat
		// Im connectedCallback werden die dl-product Elemente nicht gefunden,
		// beim DOMContentLoaded Event hingegen schon.
		document.addEventListener("DOMContentLoaded", () => {
			const data = this.getData(this);
			sendData(data);
		});
	}

	getData() {
		// Die Daten für das purchase Event basieren auf der "ecommerce" Struktur aus Universal Analytics
		// Im Google Tag Manager (GTM) wird in vielen Variablen auf diese Struktur zugegriffen,
		// sodass wir hier nicht ohne Weiteres auf die neue GA4 Datenstruktur umstellen können.
		const eventdata = {
			event: "purchase"
		};
		eventdata.attributes = getCheckoutData(this);
		const products = getAllProductData(this);
		const productdata = enrichProductdata(products);
		eventdata.products = productdata;
		return eventdata;
	}
}

if (!customElements.get('dl-purchase')) {
	customElements.define('dl-purchase', DataLayerPurchase);
}

function enrichProductdata(products) {
	const productdata = [];
	for (const product of products) {
		const productid = product.id || "";
		productdata.push(
			Object.assign({}, product, getProductAttributionData(productid))
		)
	}
	return productdata;
}
