import { isEmptyObject } from "./trackingUtils";
export { buildCheckoutTrackingData };

function buildCheckoutTrackingData(checkout, products) {
  if (isEmptyObject(checkout)) {
    return {};
  }
  const trackingData = {
    event: "EECcheckout",
    ecommerce: {
      checkout: {
        actionField: {
          step: checkout.step,
          option: checkout.option
        },
        products
      }
    }
  };

  return { ...trackingData, ...filterExistingAttributes(checkout, trackingData) };
}

function filterExistingAttributes(checkout, trackingData) {
  return Object.keys(checkout)
    .filter( // filter undefined keys
      key => typeof checkout[key] !== "undefined")
    .filter( // filter attributes from actionField
      key => !Object.keys(trackingData.ecommerce.checkout.actionField).includes(key))
    .filter( //  filter attributes from ecommerce
      key => key !== "currencyCode")
    .reduce(
      (accu, key) => ({ ...accu, [key]: checkout[key] }), {});
}
