import "data-layer-helper/dist/data-layer-helper";
import getWebAnalyticsContainer from "../analytics/analytics-container";
import { initQuickLizard } from "./quick-lizard";
import consentServiceHandler from "./consent";
import { csTrackPageView, csTrackAddToCart, csTrackPurchase, csTrackDialog, csTrackDialogClose } from "./consent/services/contentsquare";
import { brazeTrackLogin, brazeTrackPurchase, brazeTrackWarenkorbAdd, brazeTrackWarenkorbRemove, brazeTrackMerkzettelAdd, brazeTrackMerkzettelRemove } from "./consent/services/braze";

const datalayer = getWebAnalyticsContainer();
let comoUpdateSent = false;
let pageViewSent = false;

observe(datalayer);

function observe(datalayer) {
	return new DataLayerHelper(datalayer, listener, true);
}

async function listener(model, message) {
	const { event } = message;
	switch (event) {
		case "como_update":
			comoUpdateSent = true;
			if (pageViewSent) {
				consentServiceHandler(model);
			}
			break;
		case "page_view":
			initQuickLizard(model);
			csTrackPageView("", model);
			if (comoUpdateSent) {
				consentServiceHandler(model);
			} else {
				pageViewSent = true;
			}
			break;
		case "login":
			brazeTrackLogin(model);
			break;
		case "add_to_cart":
			csTrackAddToCart(message);
			brazeTrackWarenkorbAdd(message);
			break;
		case "remove_from_cart":
			brazeTrackWarenkorbRemove(message);
			break;
		case "add_to_wishlist":
			brazeTrackMerkzettelAdd(message);
			break;
		case "remove_from_wishlist":
			brazeTrackMerkzettelRemove(message);
			break;
		case "purchase":
			csTrackPurchase(message);
			brazeTrackPurchase(model);
			break;
		case "dialog_view":
			csTrackDialog(message, model.seitentyp);
			break;
		case "dialog_close":
			csTrackDialogClose(message, model.seitentyp);
			break;
		default:
			break;
	}
}
