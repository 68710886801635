import { dlEventHandler, dlEventAttributeIsPresent } from "../dl-event";
import { defaultClickHandler } from "./default";

document.addEventListener("click", clickHandler);

function clickHandler(event) {
	const { target } = event;
	const dlEventAttribute = dlEventAttributeIsPresent(target);
	if (dlEventAttribute) {
		return dlEventHandler(event);
	}
	return defaultClickHandler(event);
}
