import { getCurrencyCode } from "../helpers/currencyCodeHelper";
import { removeLegacyTrackingPrefixes } from "./helper/extract-product-data";
const blacklistAttributes = ["action", "target", "href", "rel", "id", "class", "style", "src", "impression"];

/**
 * Klasse zum Erstellen der Custom Properties für den dataLayer.
 *
 * @param {Array} attributes - Alle Attribute einer Komponente als Array
 *
 * @returns {object}
 */
export class DataLayerCustomProperties {
	constructor(attributes, productAttributes = false) {
		this.attributes = attributes;
		this.productAttributes = productAttributes;
	}

	get customProperties() {
		return this.build();
	}

	build() {
		const customProperties = {};
		if (this.attributes.length === 0) {
			return customProperties;
		}
		const attrKeyValuePairs = getAttrKeyValuePairs(this.attributes, this.productAttributes);
		for (const [key, value] of Object.entries(attrKeyValuePairs)) {
			// Ignore [dl-event] Attribute
			if (key !== "dl-event") {
				Object.assign(customProperties, {
					[key]: value
				})
			}
		}
		customProperties["currency"] = getCurrencyCode(window.location);
		return customProperties;
	}
}

function getAttrKeyValuePairs(allAttributes, productAttributes) {
	const keyValuePairs = {};
	allAttributes.forEach(attribute => {
		const { name, value } = attribute;
		const blacklistAttr = blacklistAttributes.some(attr => name === attr);
		const isDataAttr = name.includes("data-");
		if (blacklistAttr || isDataAttr) return;
		const attributeKey = cleanUpAttributeName(name);
		Object.assign(keyValuePairs, {
			[attributeKey]: value
		})
	})
	return keyValuePairs;
}

function cleanUpAttributeName(name) {
	const nameWithoutTrackingPrefixes = removeLegacyTrackingPrefixes(name);
	if (name === "dl-event") {
		return nameWithoutTrackingPrefixes;
	}
	const nameWithoutHyphens = replaceHyphenWithUnderscore(nameWithoutTrackingPrefixes);
	return nameWithoutHyphens;
}

function replaceHyphenWithUnderscore(name) {
	if (name.indexOf("-") === -1) {
		return name;
	};
	return name.replaceAll("-", "_");
}
