export default {
	"name": "Braze",
	"stage": "produktion",
	"mandanten": ["2", "4", "5", "37", "51"],
	"pagetypes": "all",
	"serviceSettings": {
		scriptUrl: '//js.appboycdn.com/web-sdk/5.6/braze.min.js',
		"2": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "fcaa306d-3b1d-4b96-848d-247b72a685ce",
			"showInAppMessages": false
		},
		"4": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "b5a6f43e-a758-430d-a8e0-e57cbda89041",
			"showInAppMessages": false
		},
		"5": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "21890691-064d-49f4-8bf1-8a9eb27c8179",
			"showInAppMessages": false
		},
		"37": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "6852816b-f0fa-4104-830f-148684feb21a",
			"showInAppMessages": false
		},
		"51": {
			"sdkEndpoint": "sdk.fra-02.braze.eu",
			"apiKey": "b59bf4ad-25d9-4e7d-aa08-a149c3a16a74",
			"showInAppMessages": false
		}
	}
}
