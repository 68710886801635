import events from "./events";

const artikelPrefix = "ArtikelKlick";

export function setProductAttribution(model, message) {
	if (!message || !message.products) return;
	const productPayload = getProductData(model, message);
	for (const product of message.products) {
		const { id } = product;
		productPayload["id"] = id;
		setProductAttributionData(id, JSON.stringify(productPayload));
	}
}

export function isProductAttributionRequired(eventName) {
	return events.includes(eventName);
}

export function setProductAttributionData(id, data) {
	try {
		localStorage.setItem(`${artikelPrefix}-${id}`, data);
	} catch (e) { }
}

export function getProductAttributionData(id) {
	try {
		return JSON.parse(localStorage.getItem(`${artikelPrefix}-${id}`)) || {};
	} catch (e) {
		return {};
	}
}

export function getProductData(model, message) {
	const { component, list } = message.attributes;
	return {
		id: "",
		seitenart: model.seitentyp || "",
		produktseite: model.seite || "",
		produktsuchbegriff: model.suchbegriff || "",
		produktab_test: model.ab_test || "",
		produktkomponente: component || "",
		produktliste: list || ""
	}
}
