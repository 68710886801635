// eslint-disable-next-line no-unused-vars
import * as Sentry from "@sentry/browser";
import { makeMultiplexedTransport } from "@sentry/core";
import "data-layer-helper/dist/data-layer-helper";
import getWebAnalyticsContainer from "../analytics/analytics-container";

export { register, unregister };

const ignoreErrors = [
	// Random plugins/extensions
	'top.GLOBALS',
	// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
	'originalCreateNotification',
	'canvas.contentDocument',
	'MyApp_RemoveAllHighlights',
	'http://tt.epicplay.com',
	'Can\'t find variable: ZiteReader',
	'jigsaw is not defined',
	'ComboSearch is not defined',
	'http://loading.retry.widdit.com/',
	'atomicFindClose',
	// Facebook borked
	'fb_xd_fragment',
	// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
	// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
	'bmi_SafeAddOnload',
	'EBCallBackMessageReceived',
	// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
	'conduitPage',
	// Generic error code from errors outside the security sandbox
	// You can delete this if using raven.js > 1.0, which ignores these automatically.
	'Script error.'
];
const denyUrls = [
	// Goggle Tag Manager blocked
	/googletagmanager\.com/i,
	// Facebook flakiness
	/graph\.facebook\.com/i,
	// Facebook blocked
	/connect\.facebook\.net\/en_US\/all\.js/i,
	// Woopra flakiness
	/eatdifferent\.com\.woopra-ns\.com/i,
	/static\.woopra\.com\/js\/woopra\.js/i,
	// Chrome extensions
	/extensions\//i,
	/^chrome:\/\//i,
	// Other plugins
	/127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
	/webappstoolbarba\.texthelp\.com\//i,
	/metrics\.itunes\.apple\.com\.edgesuite\.net\//i
];

const environment = (location.host.indexOf('integration.') !== -1 || location.host.indexOf('ereader-integ.') !== -1 || location.host.indexOf('app-integration.') !== -1) ? "integration" : "produktion";
const active = shouldSentryBeActive();
const plugins = [];
if(active === true && typeof makeMultiplexedTransport === "function") {
	const config = {
		dsn: "https://588b73ef39f1aed71cbe3bf60fa62b80@sentry.thalia.de/2",
		transport: makeMultiplexedTransport(Sentry.makeFetchTransport, dsnFromRegex),
		environment,
		beforeSendTransaction: (event) => {
			try {
				const dlHelper = new DataLayerHelper(getWebAnalyticsContainer());
				event.transaction = dlHelper.get('seitentyp') || dlHelper.get('attributes.seitentyp');
			} catch(fehler) { }
			return event;
		},
		beforeSend: (event) => {
			try {
				event.setTag({
					"touchpoint": identifyTouchpoint(),
				});
			} catch(fehler) {
				console.log(fehler)
			}
			return event;
		},
		attachStacktrace: true,
		tracesSampleRate: 0.1,
		integrations: [
			Sentry.browserTracingIntegration({
				enableInp: true,
			}),
		],
		ignoreErrors,
		denyUrls,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.1
	};
	if(environment === "integration") {
		config.replaysSessionSampleRate = 1.0;
		config.replaysOnErrorSampleRate = 1.0;
	}
	Sentry.init(config);
}

function identifyTouchpoint() {
	if(location.host.indexOf("ereader") === 0) {
		return "ereader";
	}
	if(location.host.indexOf("app") === 0) {
		return "app";
	}
	return "web";
}

function shouldSentryBeActive() {
	if(location.host.indexOf('www.thalia.de') === 0) {
		return true;
	}
	if(location.host.indexOf('integration.') !== -1) {
		return true;
	}
	if(location.host.indexOf('app-integration.') !== -1) {
		return true;
	}
	if(location.host.indexOf('ereader-integ.') !== -1) {
		return true;
	}
	if(location.host.indexOf('ereader.thalia.de') === 0) {
		return true;
	}
	if(location.host.indexOf('localhost') !== -1) {
		return true;	}


	return false;
}

/**
 * Registers a plugin. The given filter is used withing Sentry.io's "beforeSend()"
 *
 * @param {{name:string,
 *  release:string,
 *  dsn:{key:string,project:string},
 *  filter: (event:any,hint:any)=> boolean}} plugin the plugin to register
 *
 *  @returns true if the Plugin was successfully registered
 *  @returns false if the Plugin was already registered
 */
function register(plugin) {
	if (!plugin) {
		// throw new Error("No plugin provided");
		return false;
	}
	plugins.push(plugin);
	return true;
}

function unregister(plugin) {}

function dsnFromRegex({ getEvent }) {
	const event = getEvent();
	let dsn = "";
	if(!!event) {
		const filename = getRelevantFilename(event);
		dsn = findFirstDSN(plugins, filename);
	}
	if(!dsn) {
		dsn = "https://588b73ef39f1aed71cbe3bf60fa62b80@sentry.thalia.de/2";
	}
	return [dsn];
}

function getRelevantFilename(event) {
	if (typeof (event?.exception?.values[0]?.stacktrace) !== 'undefined') {
		const stacktrace = event.exception.values[0].stacktrace;
		const mostRecentCall = stacktrace.frames.length - 1;
		const mostRecentFilename = stacktrace.frames[mostRecentCall].filename;
		return mostRecentFilename;
	}
}

function findFirstDSN(plugins, filename) {
	let re;
	for (let i = 0; i < plugins.length; i++) {
		re = new RegExp(plugins[i].filenameRegex[0]);
		if (re.test(filename)) {
			return plugins[i].dsn;
		}
	}
	return undefined;
}

export function sendLogErrorEvent(message) {
	window.dispatchEvent(new CustomEvent("log-error", {
		bubbles: true,
		detail: {
			message: message
		}
	}))
}

window.addEventListener("log-error", (event) => {
	const message = event.detail.message;
	return Sentry.captureException(message);
});
