import { sendData } from "../datalayer-api";
import { DataLayerEntry } from "../DataLayerEntry";

export class CustomVideo extends HTMLElement {
	#events = ["playing", "pause", "ended", "timeupdate"];

	constructor() {
		super();
		this.lastVideoProgressEventTime = null;
		this.videoPlaying = false;
		this.progressThreshold = [10, 25, 50, 75];
		if (this.video) {
			this.addListener();
		} else {
			this.videoObserver = this.initObserver();
		}
	}

	set url(value) {
		this._url = value;
	}

	get url() {
		if (this._url) {
			return this._url;
		}
		return this.dataset["videoUrl"] || this.video?.src || "";
	}

	set title(value) {
		this._title = value;
	}

	get title() {
		if (this._title) {
			return this._title;
		}
		return this.dataset["videoTitle"] || "";
	}

	set provider(value) {
		this._provider = value;
	}

	get provider() {
		if (this._provider) {
			return this._provider;
		}
		return this.dataset["videoProvider"] || "";
	}

	set video(value) {
		this._video = value;
	}

	get video() {
		if (this._video) {
			return this._video;
		}
		return this.querySelector("VIDEO");
	}

	disconnectedCallback() {
		this.removeListener();
		this.videoObserver?.disconnect();
	}

	handleEvent(event) {
		const { type } = event;
		const { readyState } = this.video;
		let eventname = null;
		switch (type) {
			case "playing":
				if (!this.videoPlaying) {
					eventname = "video_start";
					this.videoPlaying = true;
				}
				break;
			case "pause":
				if (readyState === 4) {
					// "pause" Event (readyState 4) wird auch beim "seeked" Event (readyState 1) gefeuert.
					// this.videoPlaying nur setzen, wenn "pause" nicht in Verbindung mit "seeked" gefeuert wird (readyState 4)
					this.videoPlaying = false;
				}
				break;
			case "ended":
				eventname = "video_complete";
				this.videoPlaying = false;
				break;
			case "timeupdate":
				const percent = this.getCurrentTimeInPercent();
				if (percent === this.lastVideoProgressEventTime) {
					// return console.debug("<custom-video> timeupdate", `Event with "${percent}%" already send`);
					return;
				}
				if (this.progressThreshold.includes(percent)) {
					this.lastVideoProgressEventTime = percent;
					eventname = "video_progress";
				}
				break;
			default:
				break;
		}
		if (eventname) {
			const data = this.getEventdata(eventname);
			return this.send(data);
		}
	}

	send(data) {
		return sendData(data);
	}

	initObserver() {
		const config = { childList: true, subtree: true };
		const observer = new MutationObserver(this.mutationCallback.bind(this));
		observer.observe(this, config);
		return observer;
	}

	mutationCallback(records, observer) {
		for (const record of records) {
			for (const node of record.addedNodes) {
				if (node.nodeName === "VIDEO") {
					this.video = node;
					this.addListener();
					return observer.disconnect();
				}
			}
		}
	}

	addListener() {
		for (const event of this.#events) {
			this.addEventListener(event, this, { capture: true });
		}
	}

	removeListener() {
		for (const event of this.#events) {
			this.removeEventListener(event, this, { capture: true });
		}
	}

	getEventdata(eventname) {
		return new DataLayerEntry(eventname, this, {
			video_current_time: this.video.currentTime,
			video_duration: this.video.duration,
			video_percent: this.getCurrentTimeInPercent(),
			video_provider: this.provider,
			video_title: this.title,
			video_url: this.url,
			visible: elementFullyInViewport(this.video) // TODO: wann gilt das Element als "sichtbar". Stand jetzt muss es vollständig im Viewport sein
		}).build();
	}

	getCurrentTimeInPercent() {
		return Math.round(
			(this.video.currentTime / this.video.duration) * 100
		);
	}
}

if (!customElements.get("custom-video")) {
	customElements.define("custom-video", CustomVideo);
}

function elementFullyInViewport(element) {
	const rect = element.getBoundingClientRect();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}
