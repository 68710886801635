import { DataLayerPromotion } from "../../DataLayerPromotion";

export default buildViewPromotionsData;

function buildViewPromotionsData(viewedPromotions) {
	const eventData = {
		event: "view_promotions"
	}
	const views = [];
	for (const promotion of viewedPromotions) {
		const { promotionParameters } = new DataLayerPromotion(promotion);
		views.push(promotionParameters);
	}
	eventData['promotions'] = views;
	return eventData;
}
