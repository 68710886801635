import Cookies from "js-cookie";
import servicesData from "./services/servicesData";
import DataProcessingService from "./DataProcessingService";
import {
	initContentsquare,
	initDigitalChat,
	initThaliaChat,
	initThaliaChatProd,
	initSalesforcePersonalizations,
	initBraze
} from "./services";

const servicesLoaded = [];

export default function consentServiceHandler(model) {
	// Übergangslösung TPE
	const ereaderTrackingCookie = Cookies.get("ers_trackingConsent");
	if (ereaderTrackingCookie === "true|true") {
		return defaultConsenthandler(model);
	}
	// Übergangslösung TPA
	const heimdallCookie = Cookies.get("COOKIECONSENT");
	if (window.heimdallActive && heimdallCookie === "1") {
		return defaultConsenthandler(model);
	}
	// Usercentrics
	usercentricsEventhandler(model);
}

function usercentricsEventhandler(model) {
	for (const data of servicesData) {
		const { name } = data;
		const notLoadedYet = !servicesLoaded.includes(name);
		const serviceOptedIn = model[name] === true;
		if (serviceOptedIn && notLoadedYet) {
			loadService(data, model);
		}
	}
}

function defaultConsenthandler(model) {
	for (const data of servicesData) {
		loadService(data, model);
	}
}

function loadService(data, model) {
	const dps = new DataProcessingService(data);
	const { name, validEnvironment } = dps;
	if (!validEnvironment) {
		return;
	}
	console.debug("consentApi", "loadService:", name);
	switch (name) {
		case "ThaliaChat":
			initDigitalChat(dps);
			initThaliaChat(dps);
			initThaliaChatProd(dps);
			break;
		case "ContentSquare":
			initContentsquare(dps);
			break;
		case "Braze":
			initBraze(dps, model);
			break;
		case "Salesforce Marketing Cloud":
			initSalesforcePersonalizations(dps);
			break;
		default:
			break;
	}
	servicesLoaded.push(name);
}
