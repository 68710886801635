import "data-layer-helper/dist/data-layer-helper";
import getWebAnalyticsContainer from "../../analytics/analytics-container";

export default class DataProcessingService {
	constructor(service) {
		const { name, stage, mandanten, pagetypes, serviceSettings, seitenPattern } = service;
		this.name = name;
		this.stage = stage;
		this.mandanten = mandanten;
		this.pagetypes = pagetypes;
		this.serviceSettings = serviceSettings;
		this.seitenPattern = seitenPattern;
		this.datalayer = getWebAnalyticsContainer();
		this.datalayerModel = new DataLayerHelper(this.datalayer);
	}

	get validEnvironment() {
		const validStage = this.isActiveForStage();
		const validMandant = this.isActiveForMandant();
		const validPagetype = this.isValidPagetype();
		const validPage = this.isValidPage();
		if (validStage && validMandant && validPagetype && validPage) {
			return true;
		}
		return false;
	}

	isActiveForStage() {
		if(!this.stage) {
			true;
		}
		const stage = this.datalayerModel.get("stage");
		return this.stage === "all" || this.stage === stage;
	}

	isActiveForMandant() {
		if(!this.mandanten) {
			true;
		}

		this.mandantId = this.datalayerModel.get("mandant-id");
		return this.mandanten.indexOf(this.mandantId.toString()) !== -1;
	}

	isValidPagetype() {
		if(!this.pagetypes) {
			true;
		}
		const pagetype = this.datalayerModel.get("seitentyp");
		return this.pagetypes === "all" || this.pagetypes.indexOf(pagetype) !== -1;
	}

	isValidPage() {
		if(typeof(this.seitenPattern) === "undefined") {
			return true;
		}
		const aktuelleSeite = this.datalayerModel.get("seite");
		if(aktuelleSeite.match(this.seitenPattern)) {
			return true;
		}
		return false;
	}
}
