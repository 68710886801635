let hatGeklickt = false;
let x = 0;
let y = 0;

export function addOutsideclickListener(dialog) {
    dialog.addEventListener('mousedown', mousedownHandler);
    dialog.addEventListener('mouseup', mouseupHandler);
}

function mousedownHandler(event) {
	const clickOutsideDialog = isClickOutsideDialog(event);
	if (clickOutsideDialog) {
		hatGeklickt = true;
	}
}

function mouseupHandler(event) {
	if (hatGeklickt === true && isClickOutsideDialog(event)) {
		if(event.target.hasAttribute('dialog-no-outside-close') === false) {
			if(typeof(event.target.close) !== "function" ) {
				return;
			}
			event.target.close();
			hatGeklickt = false;
		}
	}
}

function isClickOutsideDialog(event) {
	x = event.offsetX;
	y = event.offsetY;
    const dialog = event.target;
	const dialogHeight = dialog.getBoundingClientRect().height;
    const dialogWidth = dialog.getBoundingClientRect().width;
    return x < 0 || x > dialogWidth || y < 0 || y > dialogHeight;
}
