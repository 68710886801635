export function getMandantId() {
	let mandantId = "";
	try {
		mandantId = document.querySelector("[name='mandant']").content;
	} catch (e) {
		mandantId = document.body.getAttribute('data-mandant');
	}
	return mandantId;
}

export function getBaseMandantName(mandantId) {
	const baseMandantId = getBaseMandantId(mandantId);
	switch(baseMandantId) {
		case "2":
			return "thalia-de";
		case "4":
			return "thalia-at";
		case "51":
			return "osiander";
		case "37":
			return "orellfuessli";
		case "5":
			return "bol";
		default:
			return "";
	}
}
export function getBaseMandantId(mandantId) {
	switch(mandantId) {
		case "2":
		case "2003":
		case "2004":
		case "2005":
			return "2";
		case "4":
		case "4002":
		case "4003":
		case "4004":
			return "4";
		case "51":
		case "51003":
		case "51004":
			return "51";
		case "37":
		case "37002":
		case "37004":
			return "37";
		case "5":
			return "5";
		default:
			return "";
	}
}

export function isApp() {
	const mandantId = getMandantId();
	switch (mandantId) {
		case '2003':
		case '4002':
		case '37002':
		case '51003':
			return true;

		default:
			return false
	}
}
