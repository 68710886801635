/* eslint-disable no-console */
export class DataObject {
	constructor(name) {
		if (!DataObject.instances) {
			DataObject.instances = {};
		}

		if (!DataObject.instances[name]) {
			this.name = name;
			this.registeredCallbacks = [];
			DataObject.instances[name] = this;
		}
		return DataObject.instances[name];
	}

	register(callback) {
		if (this.registeredCallbacks.includes(callback)) {
			console.warn(`${this.name}: callback already registered`);
			return false;
		}
		this.registeredCallbacks.push(callback);
		return true;
	}

	unregister(callback) {
		const index = this.registeredCallbacks.indexOf(callback);
		if (index !== -1) {
			this.registeredCallbacks.splice(index, 1);
		} else {
			console.warn(`${this.name}: callback not found`);
			return false;
		}
		return true;
	}

	async getData(...meta) {
		const allPromises = [];
		this.registeredCallbacks.forEach(callback => {
			allPromises.push(promiseTimeout(3000, callback(...meta)));
		});

		return Promise.all(allPromises.map(reflect))
			.then(warnRejections)
			.then(results => {
				return results.filter(result => result.resolved).map(result => result.value);
			}
			)
			.then(data => {
				const result = {};
				data.forEach(item => {
					const lowerCaseItem = getLowerCaseObject(item);
					Object.assign(result, lowerCaseItem);
					Object.assign(result, item);
				});
				return result;
			});
	}
}

function promiseTimeout(ms, promise) {
	const timeout = new Promise((resolve, reject) => {
		const id = setTimeout(() => {
			clearTimeout(id);
			reject(new Error(`Timed out in ${ms}ms.`));
		}, ms);
	});

	return Promise.race([promise, timeout]);
}

function reflect(promise) {
	return promise.then(
		v => ({ value: v, resolved: true }),
		e => ({ cause: e, resolved: false })
	);
}

function warnRejections(promises) {
	promises
		.filter(result => !result.resolved)
		.forEach(rejection =>
			console.warn(`data-layer callback rejected: ${rejection.cause}`)
		);
	return promises;
}

function getLowerCaseObject(obj) {
	const lowerKeyObject = {};
	for (const [key, value] of Object.entries(obj)) {
		lowerKeyObject[key.toLowerCase()] = value;
	}
	return lowerKeyObject;
}
