import * as dtmApi from "../../../datalayer-api";
import clickObject from "../../../objects/ClickObject";

export { performClickTracking };

async function performClickTracking(element) {
	const trackingData = await clickObject.getData(element);
	if (Object.keys(trackingData).length === 0) {
		return;
	}
	try {
		trackingData.event = "gaEvent";
		dtmApi.sendData(trackingData);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(`click-tracking error: ${err}`);
	}
}
