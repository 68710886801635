/* eslint-disable compat/compat */
import promotionObject from "../objects/PromotionObject";

promotionObject.register(
  target =>
    new Promise((resolve, reject) => {
      const componentElement = target.closest("[component]");
      if (!componentElement) {
        reject(
          new Error(
            `Cannot get tracking data from ${target.outerHTML} \nThe element should be inside of an element with an [component] attribute.`
          )
        );
      }

      const id = componentElement.getAttribute("component");
      const creativeElement = target.closest("[promotion-creative]");

      const creative = creativeElement.getAttribute("promotion-creative");

      const name = target.closest("[promotion-name]")
        ? target.closest("[promotion-name]").getAttribute("promotion-name")
        : creative;

      const position =
        Array.from(
          creativeElement
            .closest('[impression="promotion"]')
            .querySelectorAll("[impression] [promotion-creative]")
        ).indexOf(creativeElement) + 1;

      const customProperties = Array.from(componentElement.attributes)
        .filter(attr =>
          attr.name.startsWith("cd-") ||
          attr.name.startsWith("cm-")
        )
        .reduce((accu, attr) => ({ ...accu, [attr.name.substring(3)]: attr.value }), {})

      const data = {
        name,
        creative,
        position,
        id,
        customProperties
      }
      resolve(data);
    })
);
