import submitObject from "../objects/SubmitObject";

submitObject.register((target) => {
  // eslint-disable-next-line compat/compat
  return new Promise((resolve, reject) => {
    try {
      const category = target.closest("[component]").getAttribute("component");
      const data = {
        component: category,
      };
      resolve(data);
    } catch (error) {
      reject(
        new Error(
          `Cannot get data from ${target.outerHTML} \nCaused by: ${error}`
        )
      );
    }
  });
});
