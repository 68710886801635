import { sendData } from "../datalayer-api";
import { DataLayerEntry } from "../DataLayerEntry";

export class DlEvent extends HTMLElement {
	connectedCallback() {
		if (!this.name) {
			console.info("No event name found, can not push to dataLayer. Please make sure the [name] attribute is set.");
			return;
		}
		// Timeout ist notwendig, damit JS die Elemente findet. Ohne werden die Produkte nicht gefunden.
		// Kann ersetzt werden, sobald die shell.js mit "defer" geladen werden kann.
		// Voraussetzung: alle Scripte im DOM werden mit defer geladen
		// Fix für PAC-13113
		setTimeout(() => {
			this.send();
		}, 1);
	}

	send() {
		try {
			const data = this.getData();
			sendData(data);
		} catch (error) { }
	}

	getData() {
		try {
			return new DataLayerEntry(this.name, this, {}).build();
		} catch (error) { }
	}

	get name() {
		return this.getAttribute("name");
	}
}

if (!customElements.get("dl-event")) {
	customElements.define("dl-event", DlEvent);
}
