import pageObject from "../../objects/PageObject";
import { sendData } from "../../datalayer-api";
import buildViewItemsData from "./view_items";
import buildViewPromotionsData from "./view_promotions";
import buildPageviewData from "./page_view";

let productIntersectionObserver;
let promotionIntersectionObserver;
let itemCollector = [];
let itemNodelist = [];
let promotionCollector = [];
let promotionNodelist = [];

window.addEventListener("load", pageviewHandler);
window.addEventListener('DOMContentLoaded', DOMContentLoadedHandler);
window.addEventListener('beforeunload', beforeunloadHandler);

async function pageviewHandler() {
	window.removeEventListener("load", pageviewHandler, false);
	const rawData = await pageObject.getData();
	const data = buildPageviewData(rawData);
	sendData(data);
}

function DOMContentLoadedHandler() {
	startIntersectionObserver();
	startMutationObserver();
}

function startIntersectionObserver() {
	const options = {
		threshold: 0.8
	};

	productIntersectionObserver = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				productIntersectionHandler(entry);
			}
		})
	}, options);

	promotionIntersectionObserver = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				promotionIntersectionHandler(entry);
			}
		})
	}, options);

	observationHandler(document);
}

function productIntersectionHandler(entry) {
	const { target } = entry;
	const component = target.closest("[component]");
	if (!component) return;
	const impression = component.getAttribute("impression");
	if (!impression) return;
	const componentName = component.getAttribute("component");
	const productId = target.getAttribute("product-id");
	const itemExists = itemCollector.find(item => item.component === componentName && item.productId === productId);
	if (itemExists) return;
	itemCollector.push({
		component: componentName,
		productId: productId
	});
	itemNodelist.push(target);
	if (itemNodelist.length === 50) {
		const data = buildViewItemsData(itemNodelist);
		sendData(data);
		resetItems();
	}
}

function promotionIntersectionHandler(entry) {
	const { target } = entry;
	const component = target.closest("[component]");
	if (!component) return;
	const promotionElement = target.closest("[promotion-name]");
	if (!promotionElement) return;
	const componentName = component.getAttribute("component");
	const promotionName = promotionElement.getAttribute("promotion-name");
	const promotionCreative = target.getAttribute("promotion-creative");
	const promotionExists = promotionCollector.find(item => item.component === componentName
		&& item.promotion === promotionName
		&& item.creative === promotionCreative);
	if (promotionExists) return;
	promotionCollector.push({
		component: componentName,
		promotion: promotionName,
		creative: promotionCreative
	});
	promotionNodelist.push(target);
	if (promotionNodelist.length === 50) {
		const data = buildViewPromotionsData(promotionNodelist);
		sendData(data);
		resetPromotions();
	}
}

function observationHandler(element, observe = true) {
	// MutationObserver finds some nodes, that do not have querySelectorAll
	if (!element.querySelectorAll) return;
	const products = element.querySelectorAll("[impression] [product-id],[impression][product-id]");
	for (const product of products) {
		if (observe) {
			productIntersectionObserver.observe(product);
		} else {
			productIntersectionObserver.unobserve(product);
		}
	}
	const promotions = element.querySelectorAll("[promotion-name] [promotion-creative],[promotion-name][promotion-creative]");
	for (const promotion of promotions) {
		if (observe) {
			promotionIntersectionObserver.observe(promotion);
		} else {
			promotionIntersectionObserver.unobserve(promotion);
		}
	}
}

function startMutationObserver() {
	const options = { childList: true, subtree: true };
	const observer = new MutationObserver(mutations => mutations.forEach(mutationHandler));
	observer.observe(document.body, options);
}

function mutationHandler(mutation) {
	const { type } = mutation;
	switch (type) {
		case "childList":
			mutation.addedNodes.forEach(node => observationHandler(node));
			mutation.removedNodes.forEach(node => observationHandler(node, false));
			break;
		default:
			break;
	}
}

function beforeunloadHandler() {
	if (itemNodelist.length !== 0) {
		sendData(buildViewItemsData(itemNodelist));
	}
	if (promotionNodelist.length !== 0) {
		sendData(buildViewPromotionsData(promotionNodelist));
	}
}

function resetItems() {
	itemCollector = [];
	itemNodelist = [];
}

function resetPromotions() {
	promotionCollector = [];
	promotionNodelist = [];
}
