import * as dtmApi from "../../datalayer-api";

const allowedOrigins = [
  location.origin
];

// Tracking auf Grund von Fehlern ausgeschaltet - Rücksprache mit Nathalie Jung
window.addEventListener('message', trackChat);

async function trackChat(event) {
  if (!allowedOrigins.includes(event.origin)) {
    return;
  }
  if (typeof (event.data.event) === "undefined" || event.data.event !== "datalayer") {
    return;
  }
  if (typeof (event.data.category) === "undefined" || event.data.category !== "service-chat") {
    return;
  }


  const trackingData = {
    event: "gaEvent",
    eventCategory: "service-chat",
    eventAction: event.data.action || "unbekannt"
  };

  try {
    dtmApi.sendData(trackingData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`submit-tracking error: ${err}`);
  }
}
