import "data-layer-helper/dist/data-layer-helper";
import getWebAnalyticsContainer from "../analytics/analytics-container";
import { setProductAttribution } from "./productAttribution";

function observe(dataLayer) {
	return new DataLayerHelper(dataLayer, listener, true);
}

async function listener(model, message) {
	const { event } = message;
	if (event && event === "select_item") {
		return setProductAttribution(model, message);
	}
}

const dataLayer = getWebAnalyticsContainer();
observe(dataLayer);
