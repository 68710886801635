import 'first-input-delay';
import {onINP, onTTFB, onCLS, onFID, onLCP, onFCP} from 'web-vitals';

function createVitalsPageviewElement({name, value}) {
	try {
		const attributeValue = Math.round(name === 'CLS' ? value * 1000 : value);
		const attributeName = `perf-${name}`;
		const existingElement = document.querySelector('dl-pageview[webvitals]');
		if(existingElement) {
			existingElement.setAttribute(attributeName, attributeValue);
		} else {
			const html = `<dl-pageview webvitals ${attributeName}="${attributeValue}" />`;
			document.querySelector('body').insertAdjacentHTML('beforeend', html);
		}
	} catch(fehler) {	}
}
try {
	onCLS(createVitalsPageviewElement, true);
	onFID(createVitalsPageviewElement, true);
	onLCP(createVitalsPageviewElement, true);
	onFCP(createVitalsPageviewElement, true);
	onINP(createVitalsPageviewElement, true);
	onTTFB(createVitalsPageviewElement, true);
} catch(fehler) {}
