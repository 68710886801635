import { isAppWebview, isCallbackFunctionPresent, getAppEventDatalayer } from "./call-backs-app";

class AppHide extends HTMLElement {
	connectedCallback() {
		this.datalayer = getAppEventDatalayer();
		this.callbackParam = this.getAttribute('callback-title') || "";
		this.callbackName= this.getAttribute('callback-name') || "setTitle";

		if(this.callbackName === "sendTitle" ) {
			this.callbackName = "setTitle";
		}
		if(this.callbackParam !== "" && this.isCallbackPresent(this.callbackName)) {
			this.deleteHtmlContent();
			this.sendCallbackToApp(this.callbackName, this.callbackParam);
		} else if(isAppWebview()) {
			this.deleteHtmlContent();
		}
	}

	sendCallbackToApp(callback, title) {
		if(title === "") {
			return;
		}
		this.datalayer.push({ url: '', callback: callback, params: {'title': title}});
	}

	deleteHtmlContent() {
		this.innerHTML = "";
		this.style.display = "none";
	}

	isCallbackPresent() {
		const result = isCallbackFunctionPresent(this.callbackName);
		return result;
	}
}

document.addEventListener('DOMContentLoaded', (event) => {
	if (!customElements.get('app-hide')) {
		customElements.define('app-hide', AppHide);
	}
  })
