import { polyfillAllDialog, polyfillDialog } from "./polyfill";
import { setBrowserScrollbarWidth } from "./scrollbar";
import { fixBody, bodyIsFixed } from "./fix-body";
import { addOutsideclickListener } from "./outside-click";
import { addOffcanvasChangeListener } from "./off-canvas";
window.addEventListener('DOMContentLoaded', initGlobalDialog);

function initGlobalDialog() {
	polyfillAllDialog();
	setBrowserScrollbarWidth();
	addOffcanvasChangeListener();
	// If there is already an open dialog, register outside click listener and fixBody
	const openDialog = document.body.querySelector("dialog[open]");
	if (openDialog) {
		fixBody(true);
		addOutsideclickListener(openDialog);
	}
	// Watch for <dialog> mutations
	initMutationObserver();
}

function initMutationObserver() {
	const config = {
		attributes: true,
		childList: true,
		subtree: true
	};
	const observer = new MutationObserver(mutationHandler);
	observer.observe(document.body, config);
}

function mutationHandler(mutations) {
	for (const mutation of mutations) {
		if (mutation.type === "attributes") {
			attributesHandler(mutation);
		}
		if (mutation.type === "childList") {
			childlistHandler(mutation)
		}
	}
}

function attributesHandler(mutation) {
	const { target } = mutation;
	if (target.nodeName !== "DIALOG") {
		return;
	}
	const bodyNichtFixieren = target.hasAttribute("body-not-fixed");
	if (bodyNichtFixieren) {
		return;
	}
	if (bodyIsFixed()) {
		fixBody(false);
	}
	if (target.open === true) {
		fixBody(true);
		addOutsideclickListener(target);
	}
}

function childlistHandler(mutation) {
	for (const node of mutation.addedNodes) {
		if (node.nodeName === "DIALOG") {
			polyfillDialog(node);
		}
		const possibleSubDialogs = node.querySelectorAll ? node.querySelectorAll("dialog") : [];
		if (possibleSubDialogs.length > 0) {
			possibleSubDialogs.forEach(polyfillDialog);
		}
	}
}
