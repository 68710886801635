import dialogPolyfill from 'dialog-polyfill';

export function polyfillAllDialog() {
	const dialogElementList = getAllDialog();
	dialogElementList.forEach(element => {
		polyfillDialog(element);
	});
}

export function polyfillDialog(element) {
	dialogPolyfill.registerDialog(element);
}

function getAllDialog() {
	return document.querySelectorAll('dialog');
}
