export default buildPageviewData;

function buildPageviewData(data) {
	const eventData = {
		event: "page_view"
	}
	// Sonderbehandlung für ab_test und ab-test (https://developers.google.com/analytics/devguides/collection/ga4/integration?hl=de)
	checkForExperiment(data);

	const transformedData = transformEventData(data);
	eventData["attributes"] = transformedData;
	return eventData;
}

// Wir müssen hier bestehende Custom Properties für Universal Analytics transformieren
// z.B.: mandant-id --> gültig für UA, ungültig für GA4 (hier mandant_id)
// https://developers.google.com/analytics/devguides/collection/protocol/ga4/validating-events?client_type=gtag#validation_code
function transformEventData(data) {
	const object = Object.entries(data);
	const transformedData = {};
	for (const [key, value] of object) {
		let transformedKey = key;
		if (key.includes("-")) {
			transformedKey = key.replaceAll("-", "_");
		}
		Object.assign(transformedData, {
			[transformedKey]: value
		})
	}
	return transformedData;
}

function checkForExperiment(event) {
	try {

		if(event["ab-test"]){
			createExperimentEvent(event["ab-test"], event);
		} else if(event["ab_test"]){
			createExperimentEvent(event["ab_test"], event);
		}
	} catch(fehler){}
}

function createExperimentEvent(testString, event) {
	try {
		const splittedString = testString.split("_");
		if(splittedString.length < 2) {
			return;
		}
		const test = splittedString[0].replaceAll("-", "_");
		const variante = splittedString[1].replaceAll("-", "_");
		const expVariantString = `THALIA-${test}-${variante}`;
		const htmlString = `<dl-event name="experience_impression" exp_variant_string="${expVariantString}" test="${test}" variant="${variante}"></dl-event>`;
		document.body.insertAdjacentHTML('beforeend', htmlString);
	} catch(fehler){}
}
